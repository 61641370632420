import React        from 'react';
import { Link }     from 'react-router-dom';
import Header       from '../../shared/Header';
import Menu         from '../../shared/Menu';
import Footer       from '../../shared/Footer';
import Infos        from '../../shared/Infos';
import {Container, Row, Col} from 'react-bootstrap'
import {ReactTitle} from 'react-meta-tags';

export default class Request extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerMenu: false
    };
  }

  componentWillMount() {
    if (this.props.match == null || this.props.match.params == null || this.props.match.params.id == null)
      this._redirect_to("requests");
    this.id = this.props.match.params.id;
    //if (!this.props.request)
      this.props.fetchRequest(this.id);
    //if (!this.props.messages)
      this.props.fetchMessages();
    //if (!this.props.rgpd.rgpd)
      this.props.fetchRgpd();
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  triggerMenu(){
    this.setState({triggerMenu: !this.state.triggerMenu})
  }

  render() {
    if (!this.props.isConnected && !this.props.isFetching && !this.props.isFetchingRequests && !this.props.isLogging){
      this._redirect_to("login");
    }
    console.log('RENDER REQUEST', this.props);
    let messages = (this.props.request || []).map((message, index) =>{
      return (<li key={index} className="clearfix">
      <div><p><strong>Le {message.date} à {message.heure}</strong></p></div>
      <div>
        <p><strong>Lieu : </strong>?</p>
        <p><strong>Cause : </strong> ?</p>
        <p><strong>Description de votre demande : </strong></p>
        <p>{message.message}</p>
      </div>
    </li>);
    });
    return (
      <div>
      <ReactTitle title="Mes demandes | SOGIMA & Moi"/>
      <Header triggerMenu={this.triggerMenu.bind(this)} logout={this.props.logout} history={this.props.history} notifs={this.props.messages ? this.props.messages.length : '.'} />
      <div className="page-body logged">
        <Container>
          <Row>
            <Col md={3} lg={3} sm={12}>
            <Menu opened={this.state.triggerMenu} current='requests' logout={this.props.logout}/>
            </Col>
            <Col md={9} lg={9} sm={12}>
            <div className="page-content request-single">
              <Infos user={this.props.currentUser} />
              <Link to="/requests"> Revenir à la liste de mes demandes</Link>
              {(this.props.isFetching || this.props.isLoading )? (
                <div>Chargement...</div>
              ) : (
                <div className="request-cont">
                  <div className="request-title">Votre demande d'intervention #{this.id}</div>
                  <ul>
                  { messages}
                  </ul>
                </div>
              )}
            </div>
            </Col>
            </Row>
          </Container>
        </div>
        <Footer connected='true' rgpd={this.props.rgpd.rgpd} />
      </div>
    );
  }
}
