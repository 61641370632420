import request from '../../../utils/superagent';
import { result } from 'lodash';

import {
  LOGOUT,
  LOGOUT_ERROR
} from '../constants';

/**
 * Fetch a users from the db
 * 
 * @export
 * @param {String:email, String:password}
 * @returns {Array} users
 */
export default function logout(obj = {}) {
  console.log('TRY TO LOGIN');
  // TODO : USER INPUT VALUES, NOT FILES !
  return function(dispatch) {
    window.localStorage.setItem('sogima-auth', null);
    window._csrf = null;
    window.localStorage.setItem('sogima-user', null);
    window.sogima_user = null;
    //this._redirect_to("login");
    dispatch({ type: LOGOUT });
  };
};
