import React      from 'react';


export default class Infos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
  }

  render() {
    const user = this.props.user
    if (user)
      return (
        <div className="user-infos">
          <div className="hello"> Bonjour <span>{user.prenom} {user.nom}</span></div>
          <div className="file">Dossier : {user.codeUser} - {user.n_bail} - Local n° {user.c_local}</div>
        </div>
      );
    else
    return (
      <div className="user-infos"></div>
    );
  }
}