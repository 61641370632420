import * as Constants from '../constants';

export default function RequestsReducer(state = {
  isFetchingRequests: false,
  isCreatingRequest: false,
  isFetching: false,
  isFetchingParams: false,
  isFetchingClosedRequests: false,
  isFetchingClosed: false,
  createRequestSuccess: false,
  hastoUpdate: false,
  error: null,
  params: null,
  requests: null,
  closedRequests: null
}, action) {
  switch (action.type) {
    case Constants.FETCHING_REQUESTS:
      return {
        ...state,
        isFetchingRequests: true,
        isFetching: true
      };
    case Constants.FETCHING_CLOSED_REQUESTS:
      return {
        ...state,
        isFetchingClosedRequests: true,
        isFetchingClosed: true
      };
    case Constants.FETCHED_REQUESTS:
      return {
        ...state,
        isFetchingRequests: false,
        requests: action.demands,
        isFetching: false
      };
    case Constants.FETCHED_CLOSED_REQUESTS:
      return {
        ...state,
        isFetchingClosedRequests: false,
        isFetchingClosed: false,
        closedRequests: action.closedDemands,
      };
    case Constants.FETCHED_REQUESTS_ERROR:
      return {
        ...state,
        isFetchingRequests: false,
        error: action.error,
        isFetching: false
      };
    case Constants.FETCHING_REQUEST:
      return {
        ...state,
        isFetchingRequest: true,
        isFetching: true
      };
    case Constants.FETCHED_REQUEST:
      return {
        ...state,
        isFetchingRequest: false,
        request: action.request,
        isFetching: false
      };
    case Constants.FETCHED_REQUEST_ERROR:
      return {
        ...state,
        isFetchingRequest: false,
        error: action.error,
        isFetching: false
      };
    case Constants.CREATING_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Constants.CREATED_REQUEST:
      return {
        ...state,
        isCreatingRequest: false,
        createRequestSuccess: true
      };
    case Constants.CREATED_REQUEST_ERROR:
      return {
        ...state,
        isCreatingRequest: false,
        error: action.error,
      };
      case Constants.UPDATING_REQUEST:
      return {
        ...state,
        hastoUpdate: false,
      };
    case Constants.UPDATED_REQUEST:
      return {
        ...state,
        hastoUpdate: true
      };
    case Constants.UPDATED_REQUEST_ERROR:
      return {
        ...state,
        hastoUpdate: false,
        error: action.error,
      };
    case Constants.FETCHING_PARAMS:
      return {
        ...state,
        isFetchingParams: true,
      };
    case Constants.FETCHED_PARAMS:
      return {
        ...state,
        params: action.params,
        isFetchingParams: false,
      };
    case Constants.FETCHED_PARAMS_ERROR:
      return {
        ...state,
        isFetchingParams: false,
        error: action.error,
      };
    default:
      return state;
  }
};
