import React  from 'react';
import Header from '../../shared/Header';
import Menu   from '../../shared/Menu';
import Footer from '../../shared/Footer';
import Infos  from '../../shared/Infos';

import {Container, Row, Col} from 'react-bootstrap';
import {ReactTitle} from 'react-meta-tags';
import moment from 'moment';

export default class Messages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerMenu: false
    };

    console.log('MESSAGES CONSTRUCTOR', this.props);
  }

  componentWillMount() {
    //if (!this.props.currentUser)
      this.props.checkLogin();
    //if (!this.props.messages)
      this.props.fetchMessages();
    //if (!this.props.rgpd.rgpd)
      this.props.fetchRgpd();
  }

  triggerMenu(){
    this.setState({triggerMenu: !this.state.triggerMenu})
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  render() {
    console.log('MESSAGES RENDER', this.props);

    const user = this.props.currentUser;

    if (!this.props.isConnected && !this.props.isLogging && !this.props.isFetching){
      this._redirect_to('login');
    }
    console.log('HOME RENDER USER', user);
    let messages = (this.props.messages || []).map((message, ind) => {
        return (
        <div className="message-item" key={ind} id={"id-" + ind}>
          <div className="message-date"><strong>{moment(message.date).format('DD/MM/YYYY')} à {message.heure}</strong></div>
          <div className="message-subject"><strong>Sujet : {message.typeActu}</strong></div>
          <div>{message.message}</div>
        </div>);
    });
    return (
      <div>
        <ReactTitle title="Mes messages personnels | SOGIMA & Moi"/>
        <Header triggerMenu={this.triggerMenu.bind(this)} logout={this.props.logout} history={this.props.history} notifs={(this.props.messages || []).length} />
        <div className="page-body logged">
          <Container>
            <Row>
              <Col md={3} lg={3}>
                <Menu opened={this.state.triggerMenu} current='' logout={this.props.logout}/>
              </Col>
              <Col md={9} lg={9}>
                <div className="page-content">
                  <div>
                    <Infos user={user} />
                    <div className="panel-title">Vos messages personnels</div>
                    <div className="messages-panel">
                    {(this.props.isLogging || this.props.isFetching) ? (
                      <div className={"login-spinner"}>
                        Chargement ...
                      </div>
                      ) : (
                        messages
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer rgpd={this.props.rgpd.rgpd} connected='true' />
      </div>
    );
  }
}
