import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  UPDATING_REQUEST,
  UPDATED_REQUEST,
  UPDATED_REQUEST_ERROR
} from '../constants';

/**
 * Create a request
 *
 * @export
 * @param {{ }}
 * @returns {Obj} request
 */
export default function updateRequest(requestId, obj = {}) {
  console.log('UPDATE REQUEST PUT', obj);
  return function (dispatch) {
    dispatch({ type: UPDATING_REQUEST });
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
    return request.put(`${API_URL}/reclamations/${requestId}`)
      .set({apiclef: API_KEY, jeton: jeton})
      .query(obj)
      .send(obj)
      .then(response => {
        const resp = result(response.body, 'ok');

        if (resp) {
          console.log('[Demand] has been Created', response.body);
          dispatch({ type: UPDATED_REQUEST });
        } else {
          console.log('[ERROR][Demand] has not been Created', response);
          dispatch({ type: UPDATED_REQUEST_ERROR, error: result(response.body, 'message') });
        }

        return result(response.body, 'error');
      });
  };
};
