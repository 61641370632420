export const LOGGING_USER = 'LOGGING_USER';
export const LOGGED_USER = 'LOGGED_USER';
export const LOGGED_USER_ERROR = 'LOGGED_USER_ERROR';

export const FETCHING_USER = 'FETCHING_USER';
export const FETCHED_USER = 'FETCHED_USER';
export const FETCHED_USER_ERROR = 'FETCHED_USER_ERROR';

export const EDITING_USER = 'EDITING_USER';
export const EDITED_USER = 'EDITED_USER';
export const EDITED_USER_ERROR = 'EDITED_USER_ERROR';
