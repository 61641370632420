import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_PARAMS,
  FETCHED_PARAMS,
  FETCHED_PARAMS_ERROR
} from '../constants';

/**
 * Fetch a demands from the db
 * 
 * @export
 * @returns {Array}
 */
export default function fetchCompta() {
  return function (dispatch) {
    dispatch({ type: FETCHING_PARAMS });
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
    return request.get(`${API_URL}/parametrages`)
      .set({apiclef: API_KEY, jeton: jeton})
      .query({paiements: true, ecritures: true})
      .send()
      .then(response => {
        console.log('FETCH PARAMS RESPONSE', response.body);
        const params = response.body.reponse;
        if (!!params) {
          console.log(`[Conso] has been fetched`);
          dispatch({ type: FETCHED_PARAMS, params });  
        } else {
          console.log(`[ERROR][Conso] has not been fetched`);
          dispatch({ type: FETCHED_PARAMS_ERROR, error: result(response.body, 'error') });  
        }
        return params || result(response.body, 'error');
      });
  };
};
