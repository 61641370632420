import * as Constants from '../constants';

export default function consoReducer(state = {
  isFetching: false,
  isLogging: false,
  consos: null,
  triggerModal: false
}, action) {
  console.log('REDUCERS CONSO', action.type, action);
  switch (action.type) {
    case Constants.FETCHING_CONSO:
      return {
        ...state,
        isFetching: true,
        isLogging: false,
      };
    case Constants.FETCHED_CONSO:
      return {
        ...state,
        isFetching: false,
        isLogging: false,
        consos: action.consos
      };
    case Constants.FETCHED_CONSO_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
        isLogging: false,
      };
    default: 
      return state;
  }
};
