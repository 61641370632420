import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_FORGOT_PSW,
  FETCHED_FORGOT_PSW,
  FETCHED_FORGOT_PSW_ERROR
} from '../constants';

/**
 * Fetch a users from the db
 * 
 * @export
 * @param {String:email, String:password}
 * @returns {Array} users
 */
export default function forgotPassword(obj = {}) {
  console.log('TRY TO LOGIN');
  // TODO : USER INPUT VALUES, NOT FILES !
  return function(dispatch) {
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;

    dispatch({ type: FETCHING_FORGOT_PSW });
    return request.get(`${API_URL}/utilisateurs/${obj.identifiant}/password/oublie`)
      .set({apiclef: API_KEY, utilisateur: obj.identifiant, sans_motdepasse: 1})
      .query({identifiant: obj.identifiant})
      .send()
      .then(function(response) {
        console.log('FETCH PSW RESPONSE', response.body);
        const compta = result(response.body, 'ok');
        if (compta) {
          console.log(`[Forgot psw] has been fetched`);
          dispatch({ type: FETCHED_FORGOT_PSW, pswMsg: 'Un mail vous a été envoyé afin de modifier votre mot de passe' });  
        } else {
          console.log(`[ERROR][Forgot psw] has not been fetched`);
          dispatch({ type: FETCHED_FORGOT_PSW_ERROR, pswError: 'Nous n\'avons pas pu vous envoyer de mail pour modifier votre mot de passe' });  
        }
        return compta || result(response.body, 'error');
      });
  };
};
