import React from 'react';
import MainFooter from '../../shared/MainFooter';
import MetaTags from 'react-meta-tags';
import BrowserDetection from 'react-browser-detection';


export default class Maintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showForgotPassword: false,
      userId: '',
      pswMessage: props.pswMessage,
      error: props.error,
      ieModaleOpen: true
    }
    this._handleChangeEmail = this._handleChangeEmail.bind(this);
    this._handleChangePassword = this._handleChangePassword.bind(this);
    this.try_to_connect = this.try_to_connect.bind(this);
    this._handleKeyPress = this._handleKeyPress.bind(this);
  }

  componentWillMount() {
    this.props.checkLogin();
  }

  try_to_connect() {
    this.props.login({ email: this.state.email, password: this.state.password });
  }

  _handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  _handleChangeUserId(e){this.setState({userId: e.target.value})}

  _handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.try_to_connect();
    }
  }

  closeIeModale() {
    this.setState({ieModaleOpen: false})
  }

  componentWillReceiveProps(newProps) {
    if (newProps.pswSuccess) {
      this.setState({
        showForgotPassword: false,
        pswMessage: newProps.pswMessage,
        error: newProps.error
      });
    }
    this.setState({
      pswMessage: newProps.pswMessage,
      error: newProps.error
    });
  }

  _handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  _showForgotPassword(){this.setState({showForgotPassword: true, pswMessage: '', error: ''});}

  render() {
    console.log('LOGIN RENDER', this.props, this.state);
    if (this.props.isConnected && this.props.currentUser != null) {
        this._redirect_to("home");
    }
    const tableErrors = {
      "Login failed." : "Echec de connexion.",
    };

    const browserHandler = {
      ie: () => <div className="ie-popin">
        <div className="ie-popin__block">
          <div className="popin-close" onClick={this.closeIeModale.bind(this)}>X</div>
          <p>Pour profiter de toutes les fonctionnalités du site, veuillez installer et utiliser Chrome, Firefox ou Safari</p>
          <ul>
            <li>- Chrome : <a href="https://www.google.com/intl/fr_fr/chrome/" target="_blank">https://www.google.com/intl/fr_fr/chrome/</a></li>
            <li>- Firefox : <a href="https://www.mozilla.org/fr/firefox/new" target="_blank">https://www.mozilla.org/fr/firefox/new</a></li>
            <li>- Safari : <a href="https://www.apple.com/fr/safari/" target="_blank">https://www.apple.com/fr/safari/</a></li>
          </ul>
        </div>
      </div>
    };

    return (
      <div className="maintenance-page">
      <MetaTags>
        <title>Espace Locataires | SOGIMA & Moi</title>
        <meta name="description" content="Votre espace locataire pour retrouver toutes les informations et effectuer les demandes liées à votre logement." />
      </MetaTags>
      {(() => {
        if (this.state.ieModaleOpen) {
          return(
            <BrowserDetection>
              { browserHandler }
            </BrowserDetection>
          )
        }
      })()}
      <div className="maintenance-page-wrapper">
        <img src="../img/sogima-transparent.png" alt="Logo" className="login-logo" />
        <div className="container">
          <p>Le service est temporairement indisponible pour cause de maintenance. Veuillez nous excuser pour la gêne occasionnée.</p>
<br/>
          <p>Le service sera de retour dès dimanche matin.</p>
        </div>
      </div>
      </div>
    );
  }

  modifyPasswordSubmit() {
    let form = {
      identifiant: this.state.userId
    }
    this.props.forgotPassword(form);
  }
}
