import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';
import checkLogin from './checkLogin';

import {
  LOGGING,
  LOGGED,
  LOGGED_ERROR
} from '../constants';

/**
 * Fetch a users from the db
 * 
 * @export
 * @param {String:email, String:password}
 * @returns {Array} users
 */
export default function login(obj = {}) {
  console.log('TRY TO LOGIN');
  // TODO : USER INPUT VALUES, NOT FILES !
  return function(dispatch) {
    dispatch({ type: LOGGING });
    return request.get(`${API_URL}/identifier`)
      .set({'apiclef': API_KEY, 'utilisateur': obj.email, 'motdepasse': obj.password})
      .on('error', function(err) {
        console.log(`[ERROR][Login] has not been logged`);
        console.log(err);
        dispatch({ type: LOGGED_ERROR, error: "Votre login ou votre mot de passe est erroné" });  
      })
      .then(function(response) {
        window._csrf = null;
        window.localStorage.setItem('sogima-auth', null);
        if (response.body && response.body.ok && response.body.reponse) {
          window._csrf = response.body.reponse.jeton;
          window.localStorage.setItem('sogima-auth', response.body.reponse.jeton);
          window.localStorage.setItem('sogima-user', response.body.reponse.userCode);
          console.log('[Login] has been logged',response.body.reponse );
          dispatch({ type: LOGGED, jeton: window._csrf, userId: response.body.reponse.userCode });
        } else {
          console.log('[ERROR][Login] has not been logged', response.body);
          console.log(response);
          dispatch({ type: LOGGED_ERROR, error: result(response.body, 'err_Message') });
        }
        dispatch(checkLogin());
        return result(response.body, 'errors');
      });
  };
};
