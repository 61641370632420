export const CREATING_AUTOPAY_REQUEST = 'CREATING_AUTOPAY_REQUEST';
export const CREATED_AUTOPAY_REQUEST = 'CREATED_AUTOPAY_REQUEST';
export const CREATED_AUTOPAY_REQUEST_ERROR = 'CREATED_AUTOPAY_REQUEST_ERROR';

export const FETCHING_COMPTA = 'FETCHING_COMPTA';
export const FETCHED_COMPTA = 'FETCHED_COMPTA';
export const FETCHED_COMPTA_ERROR = 'FETCHED_COMPTA_ERROR';

export const FETCHING_FILE = 'FETCHING_FILE' ;
export const FETCHED_FILE = 'FETCHED_FILE' ;
export const FETCHED_FILE_ERROR = 'FETCHED_FILE_ERROR';

export const FETCHING_QUITTANCE = 'FETCHING_QUITTANCE' ;
export const FETCHED_QUITTANCE = 'FETCHED_QUITTANCE' ;
export const FETCHED_QUITTANCE_ERROR = 'FETCHED_QUITTANCE_ERROR';

export const FETCHING_PARAMS = 'FETCHING_PARAMS' ;
export const FETCHED_PARAMS = 'FETCHED_PARAMS' ;
export const FETCHED_PARAMS_ERROR = 'FETCHED_PARAMS_ERROR'
