import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  CREATING_REQUEST,
  CREATED_REQUEST,
  CREATED_REQUEST_ERROR
} from '../constants';

/**
 * Create a request
 *
 * @export
 * @param {{ }}
 * @returns {Obj} request
 */
export default function createRequest(obj = {}) {
  return function (dispatch) {
    dispatch({ type: CREATING_REQUEST });
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
    return request.post(`${API_URL}/reclamations`)
      .set({apiclef: API_KEY, jeton: jeton})
      .query(obj)
      .send(obj)
      .then(response => {
        const resp = result(response.body, 'ok');

        if (resp) {
          console.log(`[Demand] has been Created`);
          dispatch({ type: CREATED_REQUEST });
        } else {
          console.log('[ERROR][Demand] has not been Created', response);
          dispatch({ type: CREATED_REQUEST_ERROR, error: result(response.body, 'message') });
        }

        return result(response.body, 'error');
      });
  };
};
