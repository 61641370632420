import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_COMPTA,
  FETCHED_COMPTA,
  FETCHED_COMPTA_ERROR
} from '../constants';

/**
 * Fetch a demands from the db
 *
 * @export
 * @returns {Array}
 */
export default function fetchCompta(year = 365) {
  return function (dispatch) {
    dispatch({ type: FETCHING_COMPTA });
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
    return request.get(`${API_URL}/ecrituresComptables`)
      .set({apiclef: API_KEY, jeton: jeton})
      .query({joursMaxHistorique: year})
      .send()
      .then(response => {
        console.log('FETCH COMPTA RESPONSE', response.body);
        const compta = result(response.body.reponse, 'ecritures');
        if (!!compta) {
          console.log(`[Conso] has been fetched`);
          dispatch({ type: FETCHED_COMPTA, compta });
        } else {
          console.log(`[ERROR][Conso] has not been fetched`);
          dispatch({ type: FETCHED_COMPTA_ERROR, error: result(response.body, 'error') });
        }
        return compta || result(response.body, 'error');
      });
  };
};
