import * as Constants from '../constants';

export default function loginReducer(state = {
  isLogging: true,
  isLoggouting: false,
  isFetching: false,
  isResetting: false,
  isConnected: false,
  currentUser: null,
  triggerModal: false,
  isSending: false,
  pswMsg: null,
  pswSuccess: false
}, action) {
  console.log('REDUCERS LOGIN', action.type, action);
  switch (action.type) {
    case Constants.LOGGING:
      return {
        ...state,
        isLogging: true,
        failedCheck: false,
        error: null,
      };
    case Constants.LOGGED:
      return {
        ...state,
        isConnected: true,
        isLogging: false,
        failedCheck: false,
        error: null
      };
    case Constants.LOGGED_ERROR:
      return {
        ...state,
        isConnected: false,
        isLogging: false,
        error: action.error,
        failedCheck: false
      };
    case  Constants.CHECK_LOGGING:
      return {
        ...state,
        isConnected: true,
        isFetching: true,
        isLogging: false,
        failedCheck: false
      }
    case  Constants.CHECK_LOGGED:
      return {
        ...state,
        isConnected: true,
        isLogging: false,
        isFetching: false,
        failedCheck: false,
        currentUser: action.currentUser
      }
    case  Constants.CHECK_LOGGED_ERROR:
      return {
        ...state,
        isConnected: false,
        isFetching: false,
        failedCheck: true,
        isLogging: false
        // error: action.error
      }
    case Constants.LOGOUT:
      return{
        ...state,
        isConnected: false,
        currentUser: null,
      }
    case Constants.LOGOUT_ERROR:
      return{
        ...state,
        isConnected: false
      }
    case  Constants.LOGGOUTING:
      return {
        ...state,
        isLoggouting: true,
      }
    case  Constants.LOGGOUTED:
      return {
        ...state,
        isConnected: false,
        isLoggouting: false,
        currentUser: null,
      }
    case  Constants.LOGGOUTED_ERROR:
      return {
        ...state,
        isLoggouting: false,
        error: action.error
      }
    case  Constants.RESETTING:
      return {
        ...state,
        isResetting: true,
      }
    case  Constants.RESETTED:
      return {
        ...state,
        isConnected: false,
        isResetting: false,
        currentUser: null,
      }
    case  Constants.RESETTED_ERROR:
      return {
        ...state,
        isResetting: false,
        error: action.error
      }
    case Constants.TRIGGERMODAL:
      return {
        ...state,
        triggerModal: true,
      };
      case  Constants.FETCHING_FORGOT_PSW:
      return {
        ...state,
        isSending: true,
        pswMsg: "Demande en cours ..."
      }
    case  Constants.FETCHED_FORGOT_PSW:
      return {
        ...state,
        isSending: false,
        pswMsg: action.pswMsg,
        pswSuccess: true
      }
    case  Constants.FETCHED_FORGOT_PSW_ERROR:
      return {
        ...state,
        isSending: false,
        pswMsg: action.pswError
      }
    default:
      return state;
  }
};
