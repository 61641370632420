export const LOGGING = 'LOGGING';
export const LOGGED = 'LOGGED';
export const LOGGED_ERROR = 'LOGGED_ERROR';

export const CHECK_LOGGING = 'CHECK_LOGGING';
export const CHECK_LOGGED = 'CHECK_LOGGED';
export const CHECK_LOGGED_ERROR = 'CHECK_LOGGED_ERROR';

export const LOGGOUTING = 'LOGGOUTING';
export const LOGGOUTED = 'LOGGOUTED';
export const LOGGOUTED_ERROR = 'LOGGOUTED_ERROR';

export const RESETTING = 'RESETTING';
export const RESETTED = 'RESETTED';
export const RESETTED_ERROR = 'RESETTED_ERROR';

export const SENDING = 'SENDING';
export const SEND = 'SEND';
export const SEND_ERROR = 'SEND_ERROR';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const TRIGGERMODAL = 'TRIGGERMODAL';

export const FETCHING_FORGOT_PSW = 'FETCHING_FORGOT_PSW';
export const FETCHED_FORGOT_PSW = 'FETCHED_FORGOT_PSW';
export const FETCHED_FORGOT_PSW_ERROR = 'FETCHED_FORGOT_PSW_ERROR';