import { connect } from 'react-redux';
import AccountComponent from '../components/Account';
import fetchCompta from '../../../modules/account/actions/fetchCompta';
import downloadFile from '../../../modules/account/actions/downloadFile';
import fetchQuittance from '../../../modules/account/actions/fetchQuittance';
import fetchParams from '../../../modules/account/actions/fetchParams';
import fetchPayUrl from '../../../modules/account/actions/fetchPayUrl';

import logout from '../../../modules/login/actions/logout';
import downloadDocument from '../../../modules/documents/actions/downloadDocument';
import fetchRgpd from '../../../modules/rgpd/actions/fetchRgpd';

function mapStateToProps(state) {
  return {
    isLogging: state.login.isLogging || false,
    isFetching: state.login.isFetching || state.user.isFetching || state.account.isFetching || false,
    isConnected:  state.login.isConnected || false,
    failedCheck:  state.login.failedCheck || false,
    messages:     state.messages.messages || null,
    currentUser:  state.login.currentUser || null,
    compta:       state.account.compta || null,
    quittance:    state.account.quittance || null,
    params:       state.account.params || null,
    rgpd: state.rgpd || null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchCompta(year) {
      dispatch(fetchCompta(year));
    },
    downloadFile(id){
      dispatch(downloadFile(id));
    },
    downloadDocument(id){
      dispatch(downloadDocument(id));
    },
    fetchQuittance(quittanceId){
      dispatch(fetchQuittance(quittanceId));
    },
    fetchParams(){
      dispatch(fetchParams());
    },
    fetchPayUrl(url, amount){
      dispatch(fetchPayUrl(url, amount));
    },
    logout(){
      dispatch(logout());
    },
    fetchRgpd() {
      dispatch(fetchRgpd());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountComponent);
