import { connect } from 'react-redux';
import ConsoComponent from '../components/Conso';
import fetchConso from '../../../modules/conso/actions/fetchConso';
import checkLogin from '../../../modules/login/actions/checkLogin';
import fetchMessages from '../../../modules/messages/actions/fetchMessages';
import logout from '../../../modules/login/actions/logout';
import fetchRgpd from '../../../modules/rgpd/actions/fetchRgpd';

function mapStateToProps(state) {
  return {
    isLogging: state.login.isLogging || false,
    isFetching: state.login.isFetching || state.user.isFetching || state.conso.isFetching || false,
    isConnected:  state.login.isConnected || false,
    failedCheck:  state.login.failedCheck || false,
    messages:     state.messages.messages || null,
    currentUser:  state.login.currentUser || null,
    consos:       state.conso.consos || null,
    rgpd: state.rgpd || null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchConso(param){
      dispatch(fetchConso(param));
    },
    checkLogin(){
      dispatch(checkLogin());
    },
    fetchMessages(){
      dispatch(fetchMessages());
    },
    logout(){
      dispatch(logout());
    },
    fetchRgpd() {
      dispatch(fetchRgpd());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConsoComponent);
