import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_REQUESTS,
  FETCHING_CLOSED_REQUESTS,
  FETCHED_REQUESTS,
  FETCHED_REQUESTS_ERROR,
  FETCHED_CLOSED_REQUESTS,
  FETCHED_CLOSED_REQUESTS_ERROR
} from '../constants';

/**
 * Fetch user's requests
 *
 * @export
 * @returns {Array}
 */
export default function fetchDemands(clotured) {
  return function (dispatch) {
    dispatch({ type: clotured == 1 ? FETCHING_CLOSED_REQUESTS : FETCHING_REQUESTS });
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
    return request.get(`${API_URL}/reclamations`)
      .set({apiclef: API_KEY, jeton: jeton})
      .query({cloturer: clotured})
      .then(response => {
        var url = response.req.url;
        // query clotured = 1
        if(url.indexOf("cloturer=1") > -1) {
          const closedDemands = result(response.body.reponse, 'reclamations');

          if (!!closedDemands) {
            console.log(`[Demands] has been fetched`);
            dispatch({ type: FETCHED_CLOSED_REQUESTS, closedDemands });
          } else {
            console.log('[ERROR][Demands] has not been fetched', response);
            dispatch({ type: FETCHED_CLOSED_REQUESTS_ERROR, error: result(response.body, 'error') });
          }
          return closedDemands || result(response.body, 'error');
        }
        // query clotured = false
        else {
          const demands = result(response.body.reponse, 'reclamations');

          if (!!demands) {
            console.log(`[Demands] has been fetched`);
            dispatch({ type: FETCHED_REQUESTS, demands });
          } else {
            console.log('[ERROR][Demands] has not been fetched', response);
            dispatch({ type: FETCHED_REQUESTS_ERROR, error: result(response.body, 'error') });
          }
          return demands || result(response.body, 'error');

        }
      });
  };
};
