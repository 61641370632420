import * as Constants from '../constants';

export default function rgpdReducer(state = {
  rgpd: null
}, action) {
  console.log('REDUCERS RGPD', action.type, action);
  switch (action.type) {
    case Constants.FETCHING_RGPD:
      return {
        ...state
      };
    case Constants.FETCHED_RGPD:
      return {
        ...state,
        rgpd: action.rgpd
      };
    case Constants.FETCHED_RGPD_ERROR:
      return {
        ...state,
        error: action.error
      };
    default:
      return state;
  }
};
