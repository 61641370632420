import React                 from 'react';
import Select                from 'react-select';
import Modal                 from 'react-modal';
import Header                from '../../shared/Header';
import Menu                  from '../../shared/Menu';
import Footer                from '../../shared/Footer';
import Infos                 from '../../shared/Infos';
import PayModal              from './PayModal';
import {Container, Row, Col} from 'react-bootstrap'
import {ReactTitle}          from 'react-meta-tags';
import moment                from 'moment';

Modal.setAppElement('#main');

export default class Account extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        modalIsOpen: false,
        selectedOption: { value: '', label: 'Année en cours'},
        triggerMenu: false
    };

    // Options for selectbox
    this.options = [
      { value: '', label: 'Année en cours'},
      { value: '2018', label: '2018'}
    ];

    this.avisEcheance = null;
  }

  triggerMenu(){
    this.setState({triggerMenu: !this.state.triggerMenu})
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  componentWillMount() {
    //if (!this.props.compta)
      this.props.fetchCompta();
    //if (!this.props.params || !this.props.params.ecritures)
      this.props.fetchParams();
    //if (!this.props.rgpd.rgpd)
      this.props.fetchRgpd();
  }

  _openModal() {
    this.setState({ modalIsOpen: true });
  }

  _closeModal() {
    this.setState({ modalIsOpen: false });
  }

  handleChange(selectedOption) {
    console.log('HANDLE CHANGE', selectedOption);
    this.setState({selectedOption: selectedOption});
    this.props.fetchCompta(selectedOption.value)
  }

  downloadFile() {
    this.props.downloadFile();
  }

  downloadPdf(file, ev){
    console.log('Download pdf', file);
    this.props.downloadDocument(file);
  }

  payNow(amount){
    console.log('PAY NOW', amount, this.props.params.paiements.urlPaiement)
    this.props.fetchPayUrl(this.props.params.paiements.urlPaiement, amount);
  }

  render() {
    console.log('RENDER ACCOUNT', this.props)
    if (!this.props.isConnected){
      this._redirect_to('login');
    }

    let options = (this.props.params && this.props.params.ecritures ? this.props.params.ecritures.nbHistorique || [] : []).map((year) => {
      return { value: year.jour, label: year.nom}
    });
    let history;
    if(this.props.compta) {
      let lastSolde = this.props.compta.soldeTotal;
      let modalLastSolde = this.props.compta.soldeTotal;

      let foundValue = false;
      let test = (this.props.compta.lignes || []).forEach((line, index) => {
        let mouvement = line.credit === ".0000" ? -Number(line.debit).toFixed(2) :  Number(line.credit).toFixed(2);
        if(line.avis !== '' && !foundValue) {
          console.log("____ INDEX : ", index);
          this.avisEcheance = modalLastSolde;
          foundValue = true;
        }
        modalLastSolde = modalLastSolde - mouvement;
      })

      history = (this.props.compta.lignes || []).map((line, ind) => {
        let mouv = line.credit === ".0000" ? "- " + String(Number(line.debit).toFixed(2)) : "+ " +String(Number(line.credit).toFixed(2));
        let mouvement = line.credit === ".0000" ? -Number(line.debit).toFixed(2) :  Number(line.credit).toFixed(2);

        //let mouv = Number(line.credit) - Number(line.debit);
        let ret = (<tr key={ind}>
          <td>{moment(line.dateCompta).format('DD/MM/YYYY') }</td>
          <td>{line.lib}</td>
          <td>{mouv}&nbsp;€</td>
          <td>{lastSolde.toFixed(2)}&nbsp;€</td>
          <td><div className="download-pdf">{line.avis !== null && line.avis !== "" ? (<img onClick={this.downloadPdf.bind(this, line.avis)} src="../img/pdf.png" alt="Download PDF"/>) : ""}</div></td>
          <td><div className="download-pdf">{line.quittance !== null && line.quittance !== "" ? (<img onClick={this.downloadPdf.bind(this, line.quittance)} src="../img/pdf.png" alt="Download PDF"/>) : ""}</div></td>
        </tr>);
        lastSolde = lastSolde - mouvement;
        return ret;
      })
    }

    return (
      <div>
        {this.props.isLogging || !this.props.compta ? (
          <div className={"login-spinner"}>

          </div>
        ) : (
          <Modal
              isOpen={this.state.modalIsOpen}
              shouldCloseOnEsc={true}
              shouldCloseOnOverlayClick={true}
              className="Modal"
              overlayClassName="Overlay"
            >
              <PayModal
                closeModal={this._closeModal.bind(this)}
                account={this.props.compta}
                quittance={this.props.quittance}
                fetchQuittance={this.props.fetchQuittance.bind(this)}
                payNow={this.payNow.bind(this)}
                currentUser={this.props.currentUser}
                avisEcheance={this.avisEcheance}
              />
          </Modal>
        )}
        <ReactTitle title="Mon compte | SOGIMA & Moi"/>
        <Header triggerMenu={this.triggerMenu.bind(this)} history={this.props.history} notifs={this.props.messages ? this.props.messages.length : '.'} logout={this.props.logout} />
        <div className="page-body logged">
          <Container>

          <Row>
            <Col md={3} lg={3}>
              <Menu opened={this.state.triggerMenu} current='account' logout={this.props.logout} />
            </Col>

            <Col md={9} lg={9}>
            <div className="page-content">
             <Infos user={this.props.currentUser} />
              <div className="current-balance">
                <p>Votre solde en cours : </p>
                <div className="balance-amount">{!this.props.compta ? '.' : this.props.compta.soldeTotal}€</div>
                <button className="orange-button" onClick={this._openModal.bind(this)}>Payer maintenant</button>
              </div>
              <div className="historique-bloc">
                <div className="download-bloc">
                  <p>Télécharger mon relevé</p>
                  <button onClick={this.downloadFile.bind(this)} className="orange-button">Télécharger</button>
                </div>

                <p>Consulter l'historique du relevé sur :</p>
                <Select
                  value={this.state.selectedOption}
                  onChange={this.handleChange.bind(this)}
                  options={options}
                  className="custom-react-select"
                />
              </div>

              <div className="tab">
                <table>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Libellé</th>
                      <th>Mouvement</th>
                      <th>Solde</th>
                      <th>Avis</th>
                      <th>Quittance</th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.props.isLogging || !this.props.compta || this.props.isFetching ? (
                <div className={"login-spinner"}>
                  Chargement ...
                </div>
              ) : (
                history
              )}
                  </tbody>
                </table>
              </div>
            </div>
            </Col>
          </Row>
          </Container>
        </div>
        <Footer connected='true' rgpd={this.props.rgpd.rgpd} />
      </div>
    );
  }
}
