import React        from 'react';
import Header       from '../../shared/Header';
import Menu         from '../../shared/Menu';
import Footer       from '../../shared/Footer';
import Infos from '../../shared/Infos';
import {ReactTitle} from 'react-meta-tags';


import {Container, Row, Col} from 'react-bootstrap'

export default class MyFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerMenu: false
    };
  }

  componentWillMount() {
    this.props.checkLogin();
    this.props.fetchDocuments();
    //if (!this.props.messages)
      this.props.fetchMessages();
    //if (!this.props.rgpd.rgpd)
      this.props.fetchRgpd();
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  triggerMenu(){
    this.setState({triggerMenu: !this.state.triggerMenu})
  }

  render() {
    console.log('DOCUMENTS RENDER', this.props);
    if (!this.props.isConnected && !this.props.isFetching  && !this.props.isLogging){
      this._redirect_to('login');
    }
    let docs = (this.props.documents || []).map((doc, ind) => {
      return(<li key={ind} id={ind}><div onClick={this.downloadFile.bind(this, doc)} className="orange-button download">{doc.comment}</div></li>)
    })
    return (
      <div>
        <ReactTitle title="Mes documents | SOGIMA & Moi" />
        <Header triggerMenu={this.triggerMenu.bind(this)} logout={this.props.logout} history={this.props.history} notifs={this.props.messages ? this.props.messages.length : '.'}  />
        <div className="page-body logged">
          <Container>
          <Row>
            <Col md={3} lg={3}>
            <Menu opened={this.state.triggerMenu} logout={this.props.logout} current='documents'/>
            </Col>
            <Col md={9} lg={9}>
            <div className="page-content">
                <Infos user={this.props.currentUser} />
                <h1>Mes documents</h1>
                {(this.props.isLogging || this.props.isFetching )? (
                  <div className={"login-spinner"}>
                    Chargement ...
                  </div>
                ) : (
                  <ul className="files-list">
                    {docs}
                  </ul>
                )}
            </div>
            </Col>
          </Row>
          </Container>
        </div>
        <Footer connected='true' rgpd={this.props.rgpd.rgpd} />
      </div>
    );
  }

  downloadFile(file, t){
    console.log('DOWNALOAD FILE', file, file.fichier, t);
    this.props.downloadDocument(file.fichier);
  }
}
