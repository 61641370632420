export const FETCHING_REQUESTS = 'FETCHING_REQUESTS';
export const FETCHED_REQUESTS = 'FETCHED_REQUESTS';
export const FETCHED_REQUESTS_ERROR = 'FETCHED_REQUESTS_ERROR';

export const FETCHING_CLOSED_REQUESTS = 'FETCHING_CLOSED_REQUESTS';
export const FETCHED_CLOSED_REQUESTS = 'FETCHED_CLOSED_REQUESTS';
export const FETCHED_CLOSED_REQUESTS_ERROR = 'FETCHED_CLOSED_REQUESTS_ERROR';

export const FETCHING_REQUEST = 'FETCHING_REQUEST';
export const FETCHED_REQUEST = 'FETCHED_REQUEST';
export const FETCHED_REQUEST_ERROR = 'FETCHED_REQUEST_ERROR';

export const CREATING_REQUEST = 'CREATING_REQUEST';
export const CREATED_REQUEST = 'CREATED_REQUEST';
export const CREATED_REQUEST_ERROR = 'CREATED_REQUEST_ERROR';

export const UPDATING_REQUEST = 'UPDATING_REQUEST';
export const UPDATED_REQUEST = 'UPDATED_REQUEST';
export const UPDATED_REQUEST_ERROR = 'UPDATED_REQUEST_ERROR';

export const TRIGGERMODAL = 'TRIGGERMODAL';

export const FETCHING_PARAMS = 'FETCHING_PARAMS' ;
export const FETCHED_PARAMS = 'FETCHED_PARAMS' ;
export const FETCHED_PARAMS_ERROR = 'FETCHED_PARAMS_ERROR';