import React from 'react';
import MainFooter from '../../shared/MainFooter';
import MetaTags from 'react-meta-tags';
import BrowserDetection from 'react-browser-detection';


export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showForgotPassword: false,
      userId: '',
      pswMessage: props.pswMessage,
      error: props.error,
      ieModaleOpen: true
    }
    this._handleChangeEmail = this._handleChangeEmail.bind(this);
    this._handleChangePassword = this._handleChangePassword.bind(this);
    this.try_to_connect = this.try_to_connect.bind(this);
    this._handleKeyPress = this._handleKeyPress.bind(this);
  }

  componentWillMount() {
    this.props.checkLogin();
  }

  try_to_connect() {
    this.props.login({ email: this.state.email, password: this.state.password });
  }

  _handleChangeEmail(event) {
    this.setState({ email: event.target.value });
  }

  _handleChangeUserId(e){this.setState({userId: e.target.value})}

  _handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.try_to_connect();
    }
  }

  closeIeModale() {
    this.setState({ieModaleOpen: false})
  }

  componentWillReceiveProps(newProps) {
    if (newProps.pswSuccess) {
      this.setState({
        showForgotPassword: false,
        pswMessage: newProps.pswMessage,
        error: newProps.error
      });
    }
    this.setState({
      pswMessage: newProps.pswMessage,
      error: newProps.error
    });
  }

  _handleChangePassword(event) {
    this.setState({ password: event.target.value });
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  _showForgotPassword(){this.setState({showForgotPassword: true, pswMessage: '', error: ''});}

  render() {
    console.log('LOGIN RENDER', this.props, this.state);
    if (this.props.isConnected && this.props.currentUser != null) {
        this._redirect_to("home");
    }
    const tableErrors = {
      "Login failed." : "Echec de connexion.",
    };

    const browserHandler = {
      ie: () => <div className="ie-popin">
        <div className="ie-popin__block">
          <div className="popin-close" onClick={this.closeIeModale.bind(this)}>X</div>
          <p>Pour profiter de toutes les fonctionnalités du site, veuillez installer et utiliser Chrome, Firefox ou Safari</p>
          <ul>
            <li>- Chrome : <a href="https://www.google.com/intl/fr_fr/chrome/" target="_blank">https://www.google.com/intl/fr_fr/chrome/</a></li>
            <li>- Firefox : <a href="https://www.mozilla.org/fr/firefox/new" target="_blank">https://www.mozilla.org/fr/firefox/new</a></li>
            <li>- Safari : <a href="https://www.apple.com/fr/safari/" target="_blank">https://www.apple.com/fr/safari/</a></li>
          </ul>
        </div>
      </div>
    };

    return (
      <div className="login-page">
      <MetaTags>
        <title>Espace Locataires | SOGIMA & Moi</title>
        <meta name="description" content="Votre espace locataire pour retrouver toutes les informations et effectuer les demandes liées à votre logement." />
      </MetaTags>
      {(() => {
        if (this.state.ieModaleOpen) {
          return(
            <BrowserDetection>
              { browserHandler }
            </BrowserDetection>
          )
        }
      })()}
      <div className="login-page-wrapper">
        <img src="../img/inline-logo@2x.png" alt="Logo" className="login-logo" />
        <div className="container">
          <div className="login-block">
            {/* Title of page */}
            <div className="login-title">
              <span>SOGIMA & MOI</span>
              Espace Locataires
            </div>

            {/* Login div */}
            {(() => {
              if (this.state.showForgotPassword) {
                return ( /* FORGOT PASSWORD */
                  <div>
                    <div className="login-container">
                      <div className="lost-password-title"><strong>Mot de passe oublié</strong></div>
                      <div className="login-input-container lost-password">
                        {(this.state.pswMessage ? this.state.pswMessage : '')}
                        <label htmlFor="new-pass">Votre identifiant</label>
                        <input id="user_id" onKeyPress={this._handleKeyPress} type="text" value={this.state.userId} onChange={this._handleChangeUserId.bind(this)} />
                      </div>
                      <div className="error">{ tableErrors[this.state.error] || this.state.error }</div>
                    </div>
                    <div className="login-footer">
                      <button className="login-connect-container" onClick={this.modifyPasswordSubmit.bind(this)} >Modifier votre mot de passe</button>
                    </div>
                  </div>)
                } else
                  return (  /* LOGIN */
                    <div>
                      <div className="login-container">
                        {(this.props.pswSuccess ? "un mail vous a été envoyé" : "")}
                        <div className="login-input-container">
                          <label htmlFor="identifiant">Votre identifiant</label>
                          <input id="identifiant" placeholder="Saisissez votre identifiant" onKeyPress={this._handleKeyPress} type="email" value={this.state.email} onChange={this._handleChangeEmail} />
                        </div>

                        <div className="login-input-container">
                          <label htmlFor="mdp">Votre mot de passe</label>
                          <input id="mdp" placeholder="Saisissez votre mot de passe" onKeyPress={this._handleKeyPress} type="password" value={this.state.password} onChange={this._handleChangePassword} />
                        </div>
                        {(this.props.isLogging)? (
                          <div className="error">
                          </div>
                        ) : (
                        <div className="error">{ tableErrors[this.state.error] || this.state.error }</div>
                        )}
                      </div>
                      <div className="login-footer">
                        <button className="login-connect-container" onClick={this.try_to_connect} >Se connecter</button>
                        <div onClick={this._showForgotPassword.bind(this)} className="login-forgot-container">Mot de passe oublié</div>
                      </div>
                    </div>
                  );
                })()}
          </div>

        </div>
      </div>
        <MainFooter />
      </div>
    );
  }

  modifyPasswordSubmit() {
    let form = {
      identifiant: this.state.userId
    }
    this.props.forgotPassword(form);
  }
}
