import React        from 'react';
import Select       from 'react-select';
import Header       from '../../shared/Header';
import Menu         from '../../shared/Menu';
import Footer       from '../../shared/Footer';
import Infos        from '../../shared/Infos';
import {Container, Row, Col} from 'react-bootstrap'

export default class NewRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        selectedPlace: {value: "", label: "Choisir une option", disabled: 'yes'},
        selectedReason: {value: "", label: "Choisir une option", disabled: 'yes'},
        description: "",
        triggerMenu: false
    };
  }

  componentWillMount() {
    //console.log('COMPONENT WILL MOUNT NEW REQUEST', this.props, this.state);
    //if (!this.props.currentUser)
      this.props.checkLogin();
    //if (!this.props.params || !this.props.params.COMBOLieu)
      this.props.fetchParams();
    //if (!this.props.rgpd.rgpd)
      this.props.fetchRgpd();
  }

  componentWillReceiveProps(newProps) {
    if (this.props.isFetching && !newProps.isFetching) {
      this.setState({
        params: newProps.params
      });
    }
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  _setPlace(option) {this.setState({selectedPlace: option})}

  _setReason(option) {this.setState({selectedReason: option})}

  _changeDescription(e) {this.setState({description: e.target.value})}

  triggerMenu(){
    this.setState({triggerMenu: !this.state.triggerMenu})
  }

  render() {
      const {selectedPlace} = this.state;
      let PlaceOptions = this.props.params && this.props.params.COMBOLieu ? this.props.params.COMBOLieu.map((function(lieu){
        return { value: lieu, label: lieu };
      })) : [];
      PlaceOptions.unshift({value: "", label: "Choisir une option"});
      const {selectedReason} = this.state;
      let ReasonOptions = this.props.params && this.props.params.COMBOCause ? this.props.params.COMBOCause.map((function(cause){
        return { value: cause, label: cause };
      })) : [];
      ReasonOptions.unshift({value: "", label: "Choisir une option"});

    console.log("his.props.createRequestSuccess Yo : ", this.props.createRequestSuccess)
    if(this.props.createRequestSuccess)
      this._redirect_to("requests");

    if (!this.props.isConnected && !this.props.isFetching && !this.props.isFetchingRequests && !this.props.isLogging){
      this._redirect_to("login");
    }
    return (
      <div>
        <Header triggerMenu={this.triggerMenu.bind(this)} logout={this.props.logout} history={this.props.history} notifs={(this.props.messages || []).length} />
        <div className="page-body logged">
          <Container>
            <Row>
              <Col md={3} lg={3}>
                <Menu opened={this.state.triggerMenu} current='requests' logout={this.props.logout} />
              </Col>
              <Col md={9} lg={9}>
              {(this.props.isLogging || this.props.isFetching) ? (
                <div className={"login-spinner"}>
                  Chargement ...
                </div>
              ) : (
                <div className="page-content">
                  <Infos user={this.props.currentUser} />
                  <h1>Formulaire de demande d'intervention</h1>
                  <div className="list-rappel">
                    <strong>Rappel de vos informations de contact :</strong>
                    <ul>
                      <li>Votre n° de téléphone : <strong>{this.props.currentUser.n_tel}</strong></li>
                      <li>Votre E-mail : <strong>{this.props.currentUser.mail}</strong></li>
                    </ul>
                  </div>
                  <div className="request-form form-content">
                    <div className="field-row clearfix">
                      <div className="half">
                        <label htmlFor="">Lieu (obligatoire)</label>
                        <Select value={selectedPlace}
                                onChange={this._setPlace.bind(this)}
                                options={PlaceOptions}
                                className="custom-react-select"
                                isOptionDisabled={(option) => option.disabled === 'yes'}
                                />
                      </div>
                      <div className="half">
                        <label htmlFor="">Cause (facultatif)</label>
                        <Select value={selectedReason}
                                onChange={this._setReason.bind(this)}
                                options={ReasonOptions}
                                className="custom-react-select"
                                isOptionDisabled={(option) => option.disabled === 'yes'}
                                />
                      </div>
                    </div>
                    <div className="field-row">
                      <label htmlFor="">Description de votre demande</label>
                      <textarea value={this.state.description} onChange={this._changeDescription.bind(this)} />
                    </div>
                    <button className="orange-button" onClick={this._submitForm.bind(this)}>Soumettre ma demande</button>
                  </div>
                </div>
              )}
              </Col>
            </Row>
          </Container>
        </div>
        <Footer connected='true' rgpd={this.props.rgpd.rgpd}/>
      </div>
    );
  }

  _submitForm() {
      console.log('CREATE DEMAND', this.state);
      const form = {
        typeReclamation: "TO DO",
        lieuReclamation: this.state.selectedPlace.value,
        causeRéclamation: this.state.selectedReason.value,
        newMessageReclamation: this.state.description,
        telReclamation: this.props.currentUser.n_tel,
        emailReclamation: this.props.currentUser.mail
      }
      this.props.createRequest(form);
  }
}
