import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_DOCUMENTS,
  FETCHED_DOCUMENTS,
  FETCHED_DOCUMENTS_ERROR
} from '../constants';

/**
 * Fetch a users from the db
 *
 * @export
 * @param {String:email, String:password}
 * @returns {Array} users
 */
export default function fetchDocuments(obj = {}) {
  console.log('FETCH DOCUMENTS');
  const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
  //if (!jeton)
    //dispatch({ type: FETCHING_DOCUMENTS });
  return function(dispatch) {
    dispatch({ type: FETCHING_DOCUMENTS });
    return request.get(`${API_URL}/documents?type=E/R/I/S/B/V`)
      .set({'apiclef': API_KEY, 'jeton': jeton})
      .on('error', function(err) {
        console.log(`[ERROR][Fetch documents]`);
        console.log(err);
        dispatch({ type: FETCHED_DOCUMENTS_ERROR, error: err });
      })
      .then(function(response) {
        if (response.body && response.body.ok && response.body.reponse) {
          console.log(`[Fetch documents] success`);
          dispatch({ type: FETCHED_DOCUMENTS, documents: response.body.reponse.documents });
        } else {
          console.log(`[ERROR][Fetch Documents]`);
          console.log(response);
          dispatch({ type: FETCHED_DOCUMENTS_ERROR, error: result(response.body, 'errors') });
        }
        return result(response.body, 'errors');
      });
  };
};
