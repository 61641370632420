import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  LOGGED,
  CHECK_LOGGING,
  CHECK_LOGGED,
  CHECK_LOGGED_ERROR
} from '../constants';

/**
 * Fetch a users from the db
 *
 * @export
 * @returns {Array} users
 */
export default function checkLogin() {
  console.log('CHECK LOGIN');
  const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
  const userId = window.localStorage.getItem('sogima-user') || window.sogima_user;
  return function(dispatch) {
    console.log('LOHGEDERROR before', jeton, userId, jeton === null, jeton === 'null', jeton === '', jeton === undefined);
    if (jeton === 'null' || userId === 'null'){
      console.log('LOHGEDERROR');
      dispatch({ type: CHECK_LOGGED_ERROR, error: "not connected" });
      return null;
    }
    dispatch({ type: CHECK_LOGGING });

    return request.get(`${API_URL}/utilisateurs/${userId}`)
      .set({apiclef: API_KEY, jeton: jeton})
      .query({droits: true, resume: true, coordonnees: true, JoursMaxHistorique: 1095})
      .send({})
      .on('error', function(err) {
        console.log(`[ERROR][CheckLogin] has not been logged`, err);
        dispatch({ type: CHECK_LOGGED_ERROR, error: err });
      })
      .then(function(response) {
        console.log('CHACKLOGIN RESULT', response.body.reponse);
        const currentUser = result(response.body.reponse, 'utilisateurs');
        console.log('CURRENT USER IS', currentUser);
        if (!!currentUser) {
          console.log(`[CheckLogin] has been logged`);
          dispatch({ type: CHECK_LOGGED, currentUser });
        } else {
          console.log(`[ERROR][CheckLogin] has not been logged`, response.body.err_Message);
          dispatch({ type: CHECK_LOGGED_ERROR, error: result(response.body, 'errors') });
        }
        return currentUser || result(response.body, 'errors');
    });
  };
};

function getCsrfToken() {
  if (window._csrf) return Promise.resolve(window._csrf);
  return request.get(`${API_URL}/csrf_token`)
    .on('error', function(err) {
      console.log(`[ERROR][CheckLogin] has not been logged`);
      console.log(err);
    })
    .then(function (response) {
      window._csrf = result(response.body, 'csrf_token');
    });
}
