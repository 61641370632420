import { connect }        from 'react-redux';
import RequestsComponent  from '../components/Requests';
import fetchRequests      from '../../../modules/requests/actions/fetchRequests';
import checkLogin         from '../../../modules/login/actions/checkLogin';
import updateRequest      from '../../../modules/requests/actions/updateRequest';
import fetchMessages from '../../../modules/messages/actions/fetchMessages';
import logout from '../../../modules/login/actions/logout';
import fetchRgpd from '../../../modules/rgpd/actions/fetchRgpd';

function mapStateToProps(state) {
  console.log('REQUESTS MAP TO STATE PROPS', state);
  return {
    isLogging:    state.login.isLogging || false,
    isFetching:   state.login.isFetching || state.user.isFetching || state.requests.isFetching || false,
    isConnected:  state.login.isConnected || false,
    requests:     state.requests.requests || null,
    closedRequests:  state.requests.closedRequests || null,
    isFetchingRequests: state.requests.isFetchingRequests || false,
    isFetchingClosedRequests: state.requests.isFetchingClosedRequests || false,
    isFetchingClosed: state.requests.isFetchingClosed || false,
    currentUser:  state.login.currentUser || null,
    messages:     state.messages.messages || null,
    hastoUpdate: state.requests.hastoUpdate || false,
    rgpd: state.rgpd || null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRequests(clotured){
      dispatch(fetchRequests(clotured));
    },
    checkLogin(){
      dispatch(checkLogin());
    },
    fetchMessages(){
      dispatch(fetchMessages());
    },
    updateRequest(requestId, params){
      dispatch(updateRequest(requestId, params));
    },
    logout(){
      dispatch(logout());
    },
    fetchRgpd() {
      dispatch(fetchRgpd());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestsComponent);
