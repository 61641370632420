import React        from 'react';
import Header       from '../../shared/Header';
import Menu         from '../../shared/Menu';
import Footer       from '../../shared/Footer';
import Infos        from '../../shared/Infos';
import {Container, Row, Col} from 'react-bootstrap'
import ConsoTableRow from './ConsoTableRow';
import {ReactTitle} from 'react-meta-tags';
import moment from 'moment';

export default class Conso extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      triggerMenu: false
    };
  }

  componentWillMount() {
    //if (!this.props.currentUser)
      this.props.checkLogin();
    //if (this.props.consos == null)
      this.props.fetchConso();
    //if (!this.props.messages)
      this.props.fetchMessages();
    //if (!this.props.rgpd.rgpd)
      this.props.fetchRgpd();
  }

  triggerMenu(){
    this.setState({triggerMenu: !this.state.triggerMenu})
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  displayAll(){
    this.props.fetchConso('all');
  }

  render() {
    console.log('RENDER CONSO', this.props);
    if (!this.props.isConnected && !this.props.isLogging && !this.props.isFetching){
      this._redirect_to('login');
    }
    let conso = (this.props.consos || []).slice(1).map((conso, ind) => {
        return (<ConsoTableRow data={conso} key={ind} />)
    })

    let firstConso = this.props.consos ? this.props.consos[0] : null;

    let firstConsoFormatted = firstConso ? (
      <div className="table-row">
        <div className="table-cell">
          <div><strong>Local :</strong> {firstConso.c_local}</div>
          <div><strong>Compteur :</strong> {firstConso.numCompteur || 'Aucun compteur renseigné'}</div>
          <div><strong>Bail :</strong> {firstConso.n_bail}</div>
          <div><strong>Date :</strong> {moment(firstConso.date).format('DD/MM/YYYY')}</div>
        </div>
        <div className="table-cell">{firstConso.typeConsommation}</div>
        <div className="table-cell">
          <div><strong>Ancien index :</strong> {firstConso.ancienIndex} {firstConso.libelle_unite}</div>
          <div><strong>Nouvel index :</strong> {firstConso.nouvelleIndex} {firstConso.libelle_unite}</div>
          <div><strong>> soit une consommation de :</strong> {firstConso.consommation} {firstConso.libelle_unite}</div>
        </div>
        <div className="table-cell">
          {(() => {
            switch (firstConso.infoFacture) {
              case "N":   return "Sera facturé prochainement";
              case "O": return "Déjà Facturé";
            }
          })()}
        </div>
      </div>
    ) : '';
    return (
      <div>
        <ReactTitle title="Mes index et consommations | SOGIMA & Moi"/>
        <Header triggerMenu={this.triggerMenu.bind(this)} logout={this.props.logout} history={this.props.history} notifs={this.props.messages ? this.props.messages.length : '.'} />
        <div className="page-body logged">
          <Container>
          <Row>
            <Col md={3} lg={3}>
            <Menu opened={this.state.triggerMenu} current='conso' logout={this.props.logout}/>
            </Col>
            <Col md={9} lg={9}>
            <div className="page-content">
              <Infos user={this.props.currentUser} />
              {(this.props.isLogging || this.props.isFetching )? (
                    <div className={"login-spinner"}>
                      Chargement ...
                    </div>
                  ) : (
              <div className="tab consommation">
                <section className="display-table">
                  <header className="table-row">
                    <div className="table-cell">Références</div>
                    <div className="table-cell">Type</div>
                    <div className="table-cell">Vos index et consommations</div>
                    <div className="table-cell">Facturation</div>
                  </header>

                {/* First table row */}
                  {firstConsoFormatted}
                </section>
                <div className="observation-commentary">
                  <strong>Observation/Commentaire : {firstConso ? firstConso.messageObservation : ''}</strong>
                </div>
                {/* /First table row */}

                {conso}

              </div>
            )}
            </div>
            <div className="center"><button className="display-all orange-button" onClick={this.displayAll.bind(this)}>Afficher tous les index et consommations</button></div>
            </Col>

          </Row>
          </Container>
        </div>
        <Footer rgpd={this.props.rgpd.rgpd} connected='true' />
      </div>
    );
  }
}
