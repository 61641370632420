import { connect } from 'react-redux';
import AutopayComponent from '../components/AutopayRequest';
import checkLogin from '../../../modules/login/actions/checkLogin';
import fetchMessages from '../../../modules/messages/actions/fetchMessages';
import createAutopayRequest from '../../../modules/account/actions/requestAutopay';
import fetchRgpd from '../../../modules/rgpd/actions/fetchRgpd';

function mapStateToProps(state) {
  return {
    user: state.user,
    isLoading: false, //state.user.isConnecting,
    isLogging: state.login.isLogging || false,
    isFetching: state.login.isFetching || state.user.isFetching || state.account.isFetching || false,
    isConnected:  state.login.isConnected || false,
    failedCheck:  state.login.failedCheck || false,
    messages:     state.messages.messages || null,
    currentUser:  state.login.currentUser || null,
    rgpd: state.rgpd || null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    createAutopayRequest(params) {
      dispatch(createAutopayRequest(params));
    },
    checkLogin() {
      dispatch(checkLogin());
    },
    fetchMessages(){
      dispatch(fetchMessages());
    },
    fetchRgpd() {
      dispatch(fetchRgpd());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AutopayComponent);
