import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_MESSAGES,
  FETCHED_MESSAGES,
  FETCHED_MESSAGES_ERROR
} from '../constants';

/**
 * Fetch a demands from the db
 *
 * @export
 * @returns {Array}
 */
export default function fetchConso() {
  return function (dispatch) {
    dispatch({ type: FETCHING_MESSAGES });
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
    return request.get(`${API_URL}/actualites`)
      .set({apiclef: API_KEY, jeton: jeton})
      .then(response => {
        const messages = result(response.body.reponse, 'actualites');
        if (!!messages) {
          console.log(`[Messages] has been fetched`);
          dispatch({ type: FETCHED_MESSAGES, messages });
        } else {
          console.log(`[ERROR][Messages] has not been fetched`);
          dispatch({ type: FETCHED_MESSAGES_ERROR, error: result(response.body, 'error') });
        }
        return messages || result(response.body, 'error');
      });
  };
};
