import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

// import {
//   FETCHING_PAYURL,
//   FETCHED_PAYURL,
//   FETCHED_PAYURL_ERROR
// } from '../constants';
import { join } from 'path';

/**
 * Fetch a demands from the db
 * 
 * @export
 * @returns {Array}
 */
export default function fetchQuittance(url, amount) {
    //TODO : REMOVE
  return function (dispatch) {
      let splittedUrl = url.split('=');
      splittedUrl[splittedUrl.length - 1] = amount;
      window.open(splittedUrl.join('='));
      return 'ok';
  };
};
