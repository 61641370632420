import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import ApplicationReducers from './modules/ApplicationReducers.js';
/* eslint-disable no-underscore-dangle */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const middlewares = [ thunk ];
if (process.env.REACT_APP_REDUX_DEBUG) {
  middlewares.push(createLogger());
}

export default createStore(ApplicationReducers, composeEnhancers(
  applyMiddleware(...middlewares)
));
/* eslint-enable */
