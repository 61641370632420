import request from 'superagent';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';
import { result } from 'lodash';

import {
  EDITING_USER,
  EDITED_USER,
  EDITED_USER_ERROR
} from '../constants';

/**
 * Fetch all user data
 * 
 * @export
 * @returns response || error
 */
export default function editUser(form, userId) {
  const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
  userId = userId || window.localStorage.getItem('sogima-user') || window.sogima_user;

  return function (dispatch) {
    dispatch({ type: EDITING_USER });
    return request.patch(`${API_URL}/utilisateurs/${userId}`)
      .set({apiclef: API_KEY, jeton: jeton})
      .query(form)
      .send(form)
      .then(function (res) {
        const response = res.body;
        console.log('TEST USER EDITED', res.body);
        if (response.ok) {
          dispatch({ type: EDITED_USER, form });
        } else {
          dispatch({ type: EDITED_USER_ERROR, error: result(res.body, 'err_Message') });
        }
        return response || result(res.body, 'err_Message');
      });
  };
};
