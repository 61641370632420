import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../shared/Header';
import Menu from '../../shared/Menu';
import Footer from '../../shared/Footer';
import Infos from '../../shared/Infos';
import {Container, Row, Col} from 'react-bootstrap'
import {ReactTitle} from 'react-meta-tags';
import moment from 'moment';
import BrowserDetection from 'react-browser-detection';


export default class Home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      triggerMenu: false,
      footer: '',
      ieModaleOpen: true
    };

    console.log('HOME CONSTRUCTOR', this.props);
    if (!this.props.isConnected)
      this.props.checkLogin();
  }

  triggerMenu(){
    this.setState({triggerMenu: !this.state.triggerMenu})
  }

  componentWillMount() {
    //if (!this.props.currentUser)
      this.props.checkLogin();
    //if (!this.props.messages)
      this.props.fetchMessages();
    //if (!this.props.rgpd.rgpd)
      this.props.fetchRgpd();
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  closeIeModale() {
    this.setState({ieModaleOpen: false})
  }

  componentWillReceiveProps(newProps) {
    console.log('componentWillReceiveProps HOME', newProps);
    if (!newProps.isConnected && !newProps.isLogging && !newProps.isFetching){
      console.log('componentWillReceiveProps REDIRECT TO LOGIN');
      this._redirect_to('login');
    }
    else {
      this.setState({
        triggerModal: newProps.triggerModal,
      });
    }
  }

  render() {
    console.log('HOME RENDER', this.props, this.state);
    const user = this.props.currentUser;
    if (!this.props.isConnected && !this.props.isLogging && !this.props.isFetching){
      console.log('REDIRECT TO LOGIN');
      this._redirect_to('login');
    }
    console.log('HOME RENDER USER', user);
    let messages = (this.props.messages || []).slice(0, 2).map((message, ind) => {
      return ( <li key={ind}>
        <div className="message-side-date">{moment(message.date).format('DD/MM/YYYY')}</div>
        <div className="message-side-hour">{message.heure}</div>
        <div className="message-side-subject">Sujet : {message.typeActu}</div>
        <p>{message.message}</p>
        <div className="align-right">
          <Link to={'/messages#id-' + ind}>Lire le message ></Link>
        </div>
      </li>);
    })

    const browserHandler = {
      ie: () => <div className="ie-popin">
        <div className="ie-popin__block">
          <div className="popin-close" onClick={this.closeIeModale.bind(this)}>X</div>
          <p>Pour profiter de toutes les fonctionnalités du site, veuillez installer et utiliser Chrome, Firefox ou Safari</p>
          <ul>
            <li>- Chrome : <a href="https://www.google.com/intl/fr_fr/chrome/" target="_blank">https://www.google.com/intl/fr_fr/chrome/</a></li>
            <li>- Firefox : <a href="https://www.mozilla.org/fr/firefox/new" target="_blank">https://www.mozilla.org/fr/firefox/new</a></li>
            <li>- Safari : <a href="https://www.apple.com/fr/safari/" target="_blank">https://www.apple.com/fr/safari/</a></li>
          </ul>
        </div>
      </div>
    };

    return (
      <div>
      <ReactTitle title="Accueil | SOGIMA & Moi" />
      <Header triggerMenu={this.triggerMenu.bind(this)} logout={this.props.logout} history={this.props.history} notifs={this.props.messages ? this.props.messages.length : '.'} />
      {(() => {
        if (this.state.ieModaleOpen) {
          return(
            <BrowserDetection>
              { browserHandler }
            </BrowserDetection>
          )
        }
      })()}
      <div className="page-body logged">
        <Container>
          <Row>
            <Col md={3} lg={3} sm={12}>
              <Menu opened={this.state.triggerMenu} current='home' logout={this.props.logout}/>
            </Col>
            <Col md={6} lg={6} sm={12}>
              <div className="page-content">
                <div>
                  <Infos user={user}/>
                  {(this.props.isLogging || this.props.isFetching )? (
                    <div className={"login-spinner"}>
                      Chargement...
                    </div>
                  ) : (
                      <div className="blocks-cont clearfix">
                        {user != null &&
                            <span>
                              <div className="block">
                                <div className="block-title">Votre solde en cours</div>
                                <div className="block-metric">{user.resume.solde}€</div>
                                <Link to="/account" className="orange-button">En savoir plus et payer</Link>
                              </div>
                              <div className="block">
                                <div className="block-title">Mes index<br/>et consommations</div>
                                <div className="block-metric">{user.resume.nbIndex}</div>
                                <Link to="/conso" className="orange-button">Voir mes index</Link>
                              </div>
                              <div className="block">
                                <div className="block-title">Besoin d'un<br/>document ?</div>
                                <div className="block-metric">{user.resume.nbDoc}</div>
                                <Link to="/documents" className="orange-button">Consulter mes documents</Link>
                              </div>
                              <div className="block">
                                <div className="block-title">Mes demandes</div>
                                <div className="block-metric">{user.resume.nbReclam}</div>
                                <Link to="/requests" className="orange-button">Voir mes demandes</Link>
                              </div>
                              </span>
                        }
                      </div>
                  )}
                </div>
              </div>
            </Col>
            <Col md={3} lg={3}>
              <div className="message-side">
                <div className="messages-side-title">
                  <span>Vos messages personnels</span>
                  <div className="message-count">{this.props.messages ? this.props.messages.length : '.'}</div>
                </div>
                {(this.props.isLogging || this.props.isFetching )? (
                  <div className={"login-spinner"}>
                    Chargement ...
                  </div>
                ) : (
                <ul>
                  {messages}
                </ul>
                )}
                <div className="message-side-footer">
                  <Link to="/messages" className="orange-button">Tous les messages</Link>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Footer rgpd={this.props.rgpd.rgpd} connected='true' />
      </div>
    );
  }
}
