import * as Constants from '../constants';

export default function AccountReducer(state = {
  isFetchingRequests: false,
  isCreatingRequest: false,
  error: null,
  requests: null
}, action) {
  switch (action.type) {
      case Constants.CREATING_AUTOPAY_REQUEST:
      return {
        ...state,
        isCreatingRequest: true,
      };
    case Constants.CREATED_AUTOPAY_REQUEST:
      return {
        ...state,
        isCreatingRequest: false,
      };
    case Constants.CREATED_AUTOPAY_REQUEST_ERROR:
      return {
        ...state,
        isCreatingRequest: false,
        error: action.error,
      };
    case Constants.FETCHING_COMPTA:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.FETCHED_COMPTA:
      return {
        ...state,
        isFetching: false,
        compta: action.compta
      };
    case Constants.FETCHED_COMPTA_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.FETCHING_QUITTANCE:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.FETCHED_QUITTANCE:
      return {
        ...state,
        isFetching: false,
        quittance: action.quittance
      };
    case Constants.FETCHED_QUITTANCE_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case Constants.FETCHING_PARAMS:
      return {
        ...state,
        isFetching: true,
      };
    case Constants.FETCHED_PARAMS:
      return {
        ...state,
        isFetching: false,
        params: action.params
      };
    case Constants.FETCHED_PARAMS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    default:
      return state;
  }
};
