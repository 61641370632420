import React from 'react';
import MainFooter from './MainFooter';
import {Container, Row, Col} from 'react-bootstrap'

export default class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentWillMount() {
  }

  render() {
    return (
      <div className="footer">
        <div className="fb-banner">
          <a href="https://www.facebook.com/serge.sogima.3" target="_blank">
          <img src="/img/fb.png" alt="Facebook" className="facebook-footer"/>
          Retrouvez-nous sur Facebook</a>
        </div>
        <div className="footer-wrapper">
          <Container>
            <Row>
            <Col md={12}>
            {/*<p> this.props.rgpd</p>*/}

            <a href="https://www.sogima.fr" target="_blank"><img src="/img/inline-logo@2x.png" alt="Footer Sogima Logo" className="footer-sogima-logo"/></a>

            </Col>
            <p className="adresse">6 Place du 4 Septembre, 13007 Marseille</p>
            </Row>
          </Container>
        </div>
        <MainFooter/>
        <p>Site conçu et développé par <a href="https://www.mindoza.agency" target="_blank">l'agence MINDOZA</a></p>
      </div>
    );
  }
}