import React, { Component } from 'react';
import { Route, Redirect }            from 'react-router-dom';
import './sass/App.sass';
import 'airbnb-browser-shims';
import 'babel-polyfill';
import moment                from 'moment';
import Maintenance      from './routes/maintenance/containers/Maintenance';
import Login            from './routes/login/containers/Login';
import Home             from './routes/home/containers/Home';
import Account          from './routes/account/containers/Account';
import MyFiles          from './routes/files/containers/MyFiles';
import Conso            from './routes/conso/containers/Conso';
import Requests         from './routes/requests/containers/Requests';
import Request          from './routes/requests/containers/Request';
import NewRequest       from './routes/requests/containers/NewRequest';
import EditProfile      from './routes/user/containers/EditProfile';
import AutopayRequest   from './routes/account/containers/AutopayRequest';
import Messages         from './routes/messages/containers/Messages';

window.openModale = false;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {redirect: moment() > moment("2020-06-26 23:30") && moment() < moment("2020-06-27 23:30")}
    //this.state = {redirect: moment() > moment("2020-06-23 23:30") && moment() < moment("2020-06-24 23:30")}
  }

  render() {
    if(this.state.redirect == true) {
      return (
        <>
          <Redirect to="/maintenance" />
          <Route path='/maintenance' component={Maintenance} />
        </>
      )
    }

    return (
      <div className="App">
        <Route path="/" component={Login} exact/>
        <Route path="/login" component={Login} exact/>
        <Route path="/home" component={Home} exact/>
        <Route path="/account" component={Account} exact/>
        <Route path="/documents" component={MyFiles} exact/>
        <Route path="/conso" component={Conso} exact/>
        <Route path='/requests' component={Requests} exact />
        <Route path="/requests/:id" component={ Request } />
        <Route path='/new-request' component={NewRequest} exact />
        <Route path='/edit' component={EditProfile} exact />
        <Route path='/autopay' component={AutopayRequest} exact />
        <Route path='/messages' component={Messages} exact />
        <Route path='/maintenance' component={Maintenance} exact />

      </div>
    );
  }
}

export default App;
