import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  CREATING_AUTOPAY_REQUEST,
  CREATED_AUTOPAY_REQUEST,
  CREATED_AUTOPAY_REQUEST_ERROR
} from '../constants';

/**
 * Create a request
 *
 * @export
 * @param {{ }}
 * @returns {Obj} request
 */
export default function createAutopayRequest(obj = {}) {
  const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
  console.log('AUTOPAYREQUEST ', obj);
  return function (dispatch) {
    dispatch({ type: CREATING_AUTOPAY_REQUEST });
    return request.get(`${API_URL}/etats/mandat_sepa`)
      .set({apiclef: API_KEY, jeton: jeton})
      .query({nomBanque: obj.bankName,
        iban: obj.iban,
        bic: obj.bic,
        jourPrelevement: obj.levyDay})
      .send({})
      .then(response => {
        let data = result(response.body.reponse, 'fichierPDF');

        if (!!data) {
          console.log('[Autopay request] has been Created');

          data = data.split('-').join('+')
          data = data.split('_').join('/');
          data = data.split('\n').join('');

          var binary = atob(data.replace(/\s/g, ''));
          var len = binary.length;
          var buffer = new ArrayBuffer(len);
          var view = new Uint8Array(buffer);
          for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
          }

          // create the blob object with content-type "application/pdf"
          var blob = new Blob( [view], { type: "application/pdf" });
          var url = URL.createObjectURL(blob);
          var win = window.open();
          win.document.write('<iframe src="' + url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')

          dispatch({ type: CREATED_AUTOPAY_REQUEST });
        } else {
          console.log(`[ERROR][AUtopay request] has not been Created`);

          dispatch({ type: CREATED_AUTOPAY_REQUEST_ERROR, error: result(response.body, 'message') });
        }

        return result(response.body, 'error');
      });
  };
};
