import * as Constants from '../constants';

export default function documentsReducer(state = {
  isFetching: false,
  documents: null,
  triggerModal: false,
}, action) {
  console.log('REDUCERS DOCUMENTS', action.type, action);
  switch (action.type) {
    case Constants.FETCHING_DOCUMENTS:
      return {
        ...state,
        isFetching: true
      };
    case Constants.FETCHED_DOCUMENTS:
      return {
        ...state,
        isFetching: false,
        documents: action.documents
      };
    case Constants.FETCHED_DOCUMENTS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default: 
      return state;
  }
};
