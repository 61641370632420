import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_RGPD,
  FETCHED_RGPD,
  FETCHED_RGPD_ERROR
} from '../constants';

/**
 * Fetch Rgpd
 *
 * @export
 * @returns {String}
 */
export default function fetchRgpd() {
  return function (dispatch) {
    dispatch({ type: FETCHING_RGPD });
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
    return request.get(`${API_URL}/societes?cnil=1`)
      .set({apiclef: API_KEY, jeton: jeton})
      .then(response => {
        const rgpd = result(response.body, 'reponse_Message');
        if (!!rgpd) {
          console.log(`[Rgpd] has been fetched`);
          dispatch({ type: FETCHED_RGPD, rgpd });
        } else {
          console.log(`[ERROR][Rgpd] has not been fetched`);
          dispatch({ type: FETCHED_RGPD_ERROR, error: result(response.body, 'error') });
        }
        return rgpd || result(response.body, 'error');
      });
  };
};
