import React from 'react';
import Select       from 'react-select';
import { Link }   from 'react-router-dom';
import moment from 'moment';

export default class Account extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: { value: '', label: 'Régler le solde de mon compte soit XXXX €'},
      quittance: props.quittance,
      account: this.props.quittance,
      chooseAmount: false,
      amount: 0
    };

    // Options for selectbox
    this.options = [
      { value: this.props.account.soldeTotal, label: `Régler le solde de mon compte soit ${this.props.account.soldeTotal} €`},
      { value: this.props.account.montantQuittance, label: `Régler ma dernière quittance soit ${String(parseFloat(this.props.account.montantQuittance).toFixed(2)).replace('-','')} €`},
      { value: 'choose', label: `Choisir un montant`}
    ];
  }

  componentWillMount() {
    let date = null;
    let quittanceId = null;

    let quittance = (this.props.account || {lignes: []}).lignes.forEach((line) =>{
      let lineDate = new Date(line.d_ech);
      if ( !date || lineDate > date ){
        date  = lineDate;
        quittanceId = line.n_piece;
      }
    });
    this.props.fetchQuittance(quittanceId);
  }

  componentWillReceiveProps(newProps) {

    console.log('PAYMODAL RECEIVE NEW PROPS', this.props, newProps);
    if (!newProps.isFetching) {
      console.log('PAYMODAL RECEIVE NEW PROPS bis');
      this.setState({
        selectedOption: { value: newProps.account.soldeTotal, label: `Régler le solde de mon compte soit ${newProps.account.soldeTotal} €`},
      });
    }
  }

  handleChange(selectedOption) {
    if (selectedOption.value === "choose") {
      this.setState({chooseAmount: true, selectedOption: selectedOption});
    } else
      this.setState({selectedOption: selectedOption, chooseAmount: false, amount: 0});
  }

  changeAmount(e){
    this.setState({amount: e.target.value});
  }

  pay(){
    // TODO : USE SELECTED AMOUNT !!
    console.log('PAY IN MODAL', this.state);
    let amount = this.state.chooseAmount ? this.state.amount : this.state.selectedOption.value;
    amount = Number(String(amount).replace('-', ''));
    this.props.payNow(amount);
  }

  render() {
    console.log('RENDER PAYMODAL', this.props, this.state);
    if (this.props.isFetching || this.props.isLogging || !this.props.quittance){
      return (<div>Chargement ...</div>)
    }

    this.options = [
      { value: this.props.account.soldeTotal, label: `Régler le solde de mon compte, soit ${String(this.props.account.soldeTotal).replace('-', '')} €`},
      { value: this.props.avisEcheance, label: `Régler mon dernier avis d’échéance, soit ${this.props.quittance ? String(parseFloat(this.props.avisEcheance).toFixed(2)).replace('-', '') : ''} €`},
      { value: 'choose', label: `Choisir un montant`}
    ];

    let input = this.state.chooseAmount ? (<div><input value={this.state.amount} onChange={this.changeAmount.bind(this)} className="amount" type="number"/>€</div>) : '';
    return (
      <div className="pay-cont clearfix">
        <button onClick={this.props.closeModal} className="close-modale"></button>
        <div className="left-col">
          <div className="actual-state-block">
            <div className="actual-state">
              <p>Votre compte au</p>
              <span className="bold">{moment(this.props.account.lignes[0].dateCompta).format('DD/MM/YYYY')}</span>
            </div>
            <div className="actual-state">
              <p>présente un solde de</p>
              <span className="bold">{String(this.props.account.soldeTotal).replace('-', '')}€</span>
            </div>
          </div>
          <p>Je souhaite :</p>
          <Select
            value={this.state.selectedOption}
            onChange={this.handleChange.bind(this)}
            options={this.options}
            className="custom-react-select"
          />
          {input}
          <div className="paiement-detail">
            <p>Montant à régler :</p>
            <span className="bold">{this.state.chooseAmount ? String(parseFloat(this.state.amount).toFixed(2)).replace('-', '') : String(parseFloat(this.state.selectedOption.value).toFixed(2)).replace('-', '')}€</span>
            <p className="paiement-detail-description">L’échange des données est crypté, ce qui confère une totale sécurité à votre transaction. Le montant minimum de paiement est de 1 €.</p>

           <button onClick={this.pay.bind(this)} className="orange-button">Régulariser ma situation</button>
          </div>
        </div>
        <div className="right-col">
          {(() => {
            switch(this.props.currentUser.coordonnees.prelevAuto) {
              case 'E':
               return (
                <div>
                  <p>Votre demande de mensualisation est en cours de traitement.</p>
                  <Link to="/autopay" className="disabled-button ">Adhérer au prélèvement automatique</Link>
                </div>
               );
              case 'O':
               return (
                <div>
                  <p>Vous êtes bien mensualisé.</p>
                  <Link to="/autopay" className="disabled-button ">Adhérer au prélèvement automatique</Link>
                </div>
               );
              default:
               return (
                <div>
                  <p>Vous n'avez pas encore opté pour le prélèvement automatique.</p>
                  <Link to="/autopay" className="orange-button ">Adhérer au prélèvement automatique</Link>
                </div>
               );
            }
          })()}

          <img src="../img/paiement_securise.jpg" alt="Paiement sécurisé"/>
        </div>
      </div>
    );
  }
}
