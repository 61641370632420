import React        from 'react';
import { Link }     from 'react-router-dom';
import Header       from '../../shared/Header';
import Menu         from '../../shared/Menu';
import Footer       from '../../shared/Footer';
import Infos        from '../../shared/Infos';
import {Container, Row, Col} from 'react-bootstrap';
import {ReactTitle} from 'react-meta-tags';
import moment from 'moment';

export default class EditProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        fixPhone: this.props.currentUser ? this.props.currentUser.coordonnees.telFixe || "" : "",
        email: this.props.currentUser ? this.props.currentUser.coordonnees.email || "" : "",
        mobilePhone: this.props.currentUser ? this.props.currentUser.coordonnees.telMobile || "" : "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        errorMessage: '',
        triggerMenu: false
    };
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  componentWillReceiveProps(newProps) {
    if (this.props.isFetching && !newProps.isFetching) {
      this.setState({
        fixPhone: newProps.currentUser ? newProps.currentUser.coordonnees.telFixe || "" : "",
        email: newProps.currentUser ? newProps.currentUser.coordonnees.email || "" : "",
        mobilePhone: newProps.currentUser ? newProps.currentUser.coordonnees.telMobile || "" : "",
      });
    }
  }


  componentWillMount() {
    //if (!this.props.currentUser || !this.props.isConnected){
      this.props.checkLogin();
    //}
    //if (!this.props.messages)
      this.props.fetchMessages();
    //if (!this.props.rgpd.rgpd)
      this.props.fetchRgpd();
  }

  _changeFixPhone(e) {this.setState({fixPhone: e.target.value})}
  _changeMobilePhone(e) {this.setState({mobilePhone: e.target.value})}
  _changeEmail(e) {this.setState({email: e.target.value})}

  _onOldPswChange(e) {this.setState({oldPassword: e.target.value})}
  _onNewPswChange(e) {this.setState({newPassword: e.target.value})}
  _onConfirmPswChange(e) {this.setState({confirmPassword: e.target.value})}


  triggerMenu(){
    this.setState({triggerMenu: !this.state.triggerMenu})
  }

  render() {
    const {fixPhone} = this.state;
    const {email} = this.state;
    const {mobilePhone} = this.state;
    const {oldPassword} = this.state;
    const {newPassword} = this.state;
    const {confirmPassword} = this.state;
    console.log('EDIT proFILE RENDER', this.props, this.state);
    if (!this.props.isConnected && !this.props.isLogging && !this.props.isFetching){
      console.log('REDIRECT TO LOGIN');
      this._redirect_to('login');
    }
    if (this.props.isLogging || this.props.isFetching) {
      return (
        <div className={"login-spinner"}>
          Chargement ...
        </div>
      );
    } else if (!this.props.isConnected){
      //this._redirect_to('login');
    }
    let user = this.props.currentUser;
    let coor = user.coordonnees;
    if(coor.cosignataireBail.length > 0) {
      coor.nomLoc = coor.cosignataireBail[0];
    }

    let locataires = (coor.locataires || []).map((loc, ind) => {
      return ( <div key={'loc' + ind}>
        <strong>{loc.NetP}</strong><br/>
        Né(e) le {moment(loc.dateNaiss).format('DD/MM/YYYY')} <br/>
        {loc.handicape === '1'? 'Titulaire d\'une carte d\'invalidité <br/>' : ''}
        {loc.lienParente} - {loc.situation}
        </div>);
    })

    let prelevementAuto;
    if(this.props.currentUser.coordonnees.prelevAuto == "E" || this.props.currentUser.coordonnees.prelevAuto == "O") {
      prelevementAuto = "";
    } else {
      prelevementAuto = (<div>
        <div className="panel-title">Adhérer au prélèvement automatique</div>
        <div className="panel clearfix">
          <div className="infos-left">
            <img src="/img/paiement_securise.jpg" alt="Paiement sécurisé" />
          </div>
          <div className="infos-right">
            <Link className="orange-button" to="/autopay">Accéder au formulaire de demande</Link>
          </div>
        </div>
      </div>)
    }

    return (
      <div>
        <ReactTitle title=" Modifier mes informations | SOGIMA & Moi" />
        <Header triggerMenu={this.triggerMenu.bind(this)} logout={this.props.logout} history={this.props.history} notifs={this.props.messages ? this.props.messages.length : '.'} />
        <div className="page-body logged">
          <Container>
          <Row>
            <Col md={3} lg={3}>
            <Menu opened={this.state.triggerMenu} current='edit' logout={this.props.logout}/>
            </Col>
            <Col md={9} lg={9}>
            <div className="page-content">
                <Infos user={user} />
                <h1>Mon compte</h1>
                <div className="registered-infos clearfix">
                  <div className="infos-left">
                    <p><strong>Rappel de vos informations</strong> : </p>
                    Signataire : {coor.nomLoc}<br/>
                    {coor.cosignataireBail.length > 0 ? (<span>Co-signataire: {coor.cosignataireBail[1]} <br/></span>) : ''}
                    Adresse du local : {coor.adresseLocal} <br/>
                    Programme : {coor.programme} <br/>
                    Batiment {coor.batiment}, Escalier {coor.escalier}, Etage {coor.etage}, Porte {coor.porte}, Type {coor.type} <br/>
                    Entrée le : {moment(coor.dateEntree).format('DD/MM/YYYY')}
                  </div>
                  <div className="infos-right">
                    <p><strong>Occupant(s) résidant dans le local</strong> : </p>
                    {locataires}
                  </div>
                </div>

                {/*<div className="panel-title">Adhérer au prélèvement automatique</div>
                <div className="panel clearfix">
                  <div className="infos-left">
                    <img src="/img/paiement_securise.jpg" alt="Paiement sécurisé" />
                  </div>
                  <div className="infos-right">
                    <Link className="orange-button" to="/autopay">Accéder au formulaire de demande</Link>
                  </div>
                </div> */}

                {prelevementAuto}

                <div className="clearfix">
                  <div className="edit-profile-cont edit-column">
                    <div className="panel-title">Modifier vos coordonnées de contact</div>
                    <div>{this.props.message}</div>
                    <div className="edit-panel">
                      <div className="edit-row">
                        <label htmlFor="">Téléphone fixe</label>
                        <input type="text" value={fixPhone} onChange={this._changeFixPhone}></input>
                      </div>
                      <div className="edit-row">
                        <label htmlFor="">E-mail *</label>
                        <input type="text" value={email} onChange={this._changeEmail.bind(this)}></input>
                      </div>
                      <div className="edit-row">
                        <label htmlFor="">Téléphone mobile *</label>
                        <input type="text" value={mobilePhone} onChange={this._changeMobilePhone.bind(this)} ></input>
                      </div>
                      <button className="orange-button" onClick={this.submitEditForm.bind(this)}>Enregistrer</button>
                    </div>
                  </div>
                  <div className="edit-password-cont edit-column">
                    <div className="panel-title">Modifier votre mot de passe</div>
                    <div>{this.state.errorMessage || ''}</div>
                    <div className="edit-panel">
                      <div className="edit-row">
                        <label htmlFor="">Ancien mot de passe *</label>
                        <input type="password" value={oldPassword} onChange={this._onOldPswChange.bind(this)}></input>
                      </div>
                      <div className="edit-row">
                        <label htmlFor="">Nouveau mot de passe *</label>
                        <input type="password" value={newPassword} onChange={this._onNewPswChange.bind(this)}></input>
                      </div>
                      <div className="edit-row">
                        <label htmlFor="">Confirmer le mot de passe *</label>
                        <input type="password" value={confirmPassword} onChange={this._onConfirmPswChange.bind(this)}></input>
                      </div>
                      <button className="orange-button" onClick={this.submitPwdChange.bind(this)}>Enregistrer</button>
                    </div>
                  </div>
                </div>
            </div>
            </Col>
          </Row>
          </Container>
        </div>
        <Footer rgpd={this.props.rgpd.rgpd} connected='true' />
      </div>
    );
  }

  submitEditForm() {
      console.log('Edit profile', this.state);
      const form = {
        email: this.state.email,
        telFix: this.state.fixPhone,
        telMobile: this.state.mobilePhone
      }
      this.props.editUser(form, this.props.currentUser.codeUser);
  }

  submitPwdChange() {
    console.log('Edit password', this.state);
    if (this.state.confirmPassword !== this.state.newPassword)
      this.setState({errorMessage: "Le nouveau mot de passe et le mot de passe de confirmation doivent être identiques"})
    else {
      const form = {
        password: this.state.newPassword,
        passwordOld: this.state.oldPassword
      }
      this.props.editUser(form);
    }
  }
}
