import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_FILE,
  FETCHED_FILE,
  FETCHED_FILE_ERROR
} from '../constants';

/**
 * Fetch a demands from the db
 * 
 * @export
 * @returns {Array}
 */
export default function downloadFile(year) {
  return function (dispatch) {
    //dispatch({ type: FETCHING_FILE });
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
    return request.get(`${API_URL}/etats/extrait_compte`)
      .set({apiclef: API_KEY, jeton: jeton})
      .query(year)
      .send()
      .then(response => {
        console.log('FETCH FILE RESPONSE', response.body);
        const compta = result(response.body.reponse, 'fichierPDF');
        if (!!compta) {
          console.log(`[file] has been fetched`);
          let data = compta;
          data = data.split('-').join('+');
          data = data.split('_').join('/');
          data = data.split('\n').join('');

           var binary = atob(data.replace(/\s/g, ''));
          var len = binary.length;
          var buffer = new ArrayBuffer(len);
          var view = new Uint8Array(buffer);
          for (var i = 0; i < len; i++) {
              view[i] = binary.charCodeAt(i);
          }

          // create the blob object with content-type "application/pdf"               
          var blob = new Blob( [view], { type: "application/pdf" });
          var url = URL.createObjectURL(blob);
          var win = window.open();
          win.document.write('<iframe src="' + url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
            

        } else {
          console.log(`[ERROR][file] has not been fetched`);
          //dispatch({ type: FETCHED_FILE_ERROR, error: result(response.body, 'error') });
        }
        return compta || result(response.body, 'error');
      });
  };
};
