import { connect }        from 'react-redux';
import NewRequest         from '../components/NewRequest';
import createRequest      from '../../../modules/requests/actions/createRequest';
import checkLogin         from '../../../modules/login/actions/checkLogin';
import fetchParams        from '../../../modules/requests/actions/fetchParams';
import logout from '../../../modules/login/actions/logout';
import fetchRgpd from '../../../modules/rgpd/actions/fetchRgpd';

function mapStateToProps(state) {
  console.log('MAP TO PROPS REQUESTS', state);
  return {
    isLogging:    state.login.isLogging || false,
    isFetching:   state.login.isFetching || state.user.isFetching || state.requests.isFetching || state.requests.isFetchingParams || false,
    isConnected:  state.login.isConnected || false,
    error:        state.requests.error || null,
    messages:     state.messages.messages || null,
    currentUser:  state.login.currentUser || null,
    params:       state.requests.params || null,
    createRequestSuccess: state.requests.createRequestSuccess || false,
    rgpd: state.rgpd || null
   };
}

function mapDispatchToProps(dispatch) {
  return {
    createRequest(params) {
      dispatch(createRequest(params));
    },
    checkLogin(){
      dispatch(checkLogin());
    },
    fetchParams(){
      dispatch(fetchParams());
    },
    logout(){
      dispatch(logout());
    },
    fetchRgpd() {
      dispatch(fetchRgpd());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NewRequest);
