import React      from 'react';
import { Link }   from 'react-router-dom';
import MediaQuery from 'react-responsive';

export default class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.links = [{label: "Accueil", key: 'home'},
                    {label: "Mon compte", key: 'account'},
                    {label: 'Mes documents', key: "documents"},
                    {label: "Mes index et consommations", key: 'conso'},
                    {label: "Mes demandes", key: 'requests'},
                    {label: "Modifier mes informations", key: 'edit'},
                    {label: "Consultation des charges", key: 'http://charges.sogima.fr:8081/'},];
    this.state = {
        opened: false
    };
  }

    setupBeforeUnloadListener = () => {
        window.addEventListener("beforeunload", (ev) => {
            this.props.logout();
        });
    };

    componentDidMount() {
        // Activate the event listener
        this.setupBeforeUnloadListener();
    }

  logout(){
    // this._redirect_to("login");

      // TODO : delete props on log out to prevent user from accessing pages
    this.props.logout();
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  componentWillReceiveProps(newProps) {
    this.setState({
        opened: newProps.opened,
    });
  }

  componentWillMount() {
  }

  getLink(link,label) {
      const regex = new RegExp('(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})');
      if(link.match(regex))
      {
          return <a href={link} target='_blank'>{label}</a>
      }else return <Link to={link}>{label}</Link>
  }

  render() {
    let links = this.links.map((item, ind) => {
        return (<li key={ind} className={this.props.current === item.key ? 'selected' : ''}>{this.getLink(item.key,item.label)}</li>)
    })
    return (
      <div className={this.state.opened ? "menu" : "menu hide"}>
        <ul>
          {links}
          <MediaQuery query="(max-width: 767px)">
            <li><Link to="#" onClick={this.logout.bind(this)}>Me déconnecter</Link></li>
          </MediaQuery>
        </ul>
      </div>
    );
  }
}
