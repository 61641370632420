import { connect } from 'react-redux';
import HomeComponent from '../components/Home';
import checkLogin from '../../../modules/login/actions/checkLogin';
import fetchMessages from '../../../modules/messages/actions/fetchMessages';
import logout from '../../../modules/login/actions/logout';
import fetchRgpd from '../../../modules/rgpd/actions/fetchRgpd';

function mapStateToProps(state) {
  console.log('HOME MAP TO PROPS', state);
  return {
    isLogging: state.login.isLogging || false,
    isFetching: state.login.isFetching || state.user.isFetching || false,
    isConnected:  state.login.isConnected || false,
    failedCheck:  state.login.failedCheck || false,
    currentUser:  state.login.currentUser || null,
    messages:     state.messages.messages || null,
    rgpd: state.rgpd || null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkLogin() {
      dispatch(checkLogin());
    },
    fetchMessages(){
      dispatch(fetchMessages());
    },
    logout(){
      dispatch(logout());
    },
    fetchRgpd() {
      dispatch(fetchRgpd());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeComponent);
