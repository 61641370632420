import React        from 'react';
import { Link }     from 'react-router-dom';
import Header       from '../../shared/Header';
import Menu         from '../../shared/Menu';
import Footer       from '../../shared/Footer';
import Infos        from '../../shared/Infos';
import {Container, Row, Col} from 'react-bootstrap'
import {ReactTitle} from 'react-meta-tags';
import moment from 'moment';

export default class Requests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      triggerMenu: false,
      displayClosed: false,
      fetchClosed: false
    };
  }

  componentWillMount() {
    //if (!this.props.currentUser)
      this.props.checkLogin();
      this.props.fetchRequests(false);
    //if (!this.props.messages)
      this.props.fetchMessages();
    //if (!this.props.rgpd.rgpd)
      this.props.fetchRgpd();
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  triggerMenu(){
    this.setState({triggerMenu: !this.state.triggerMenu});
  }

  fetchCloturedRequests(){
    //this.props.fetchRequests(1);
    this.setState({displayClosed: true});
  }

  fetchOpenedRequests(){
    //this.props.fetchRequests(false);
    this.setState({displayClosed: false});
  }

  componentWillReceiveProps(newProps) {
    if (!this.props.isFetching && !newProps.isFetching && newProps.hastoUpdate) {
      this.props.fetchRequests(false);
    }

    if (!this.props.isFetchingClosed && !newProps.isFetchingClosed && !this.state.fetchClosed) {
      this.props.fetchRequests(1);
      this.setState({ fetchClosed: true})
    }
  }

  requestDetail(requestId, e){
    console.log('REQUEST DETAIL', requestId);
    this._redirect_to("requests/" + requestId);
  }

  clotureRequest(requestId, e){
    this.props.updateRequest(requestId, {appelPourCloture: 'O', id_reclamation: requestId});
  }

  render() {
    console.log('RENDER REQUESTS');
    if (!this.props.isConnected && !this.props.isFetching && !this.props.isFetchingRequests && !this.props.isLogging){
      this._redirect_to("login");
    }

    let today = (this.props.requests || []).filter(function(req, ind) {
      return req.dateEmission === moment().format('YYYY/MM/DD');
    })
    console.log("LOL", today.length);
    let openedRequestCount = (this.props.requests || []).filter(function(req, ind){
     return req.bonPourCloture === "N";
    }).length;

    let requests = (this.props.closedRequests && this.state.displayClosed ? (this.props.closedRequests || []): (this.props.requests || [])).map((req, ind) => {
      return ( <div className="table-row" key={ind}>
        <div className="table-cell">
          <div className="demande-cell">
            <strong className="orange">Demande #{req.numAppel}</strong>
            <strong>Émise le {moment(req.dateEmission).format('DD/MM/YYYY')} à {req.heureEmission}</strong>
          </div>
        </div>
        <div className="table-cell">
          <p><strong>Lieu : </strong> {req.localisation}</p>
          <p><strong>Cause : </strong></p>
          <p><strong>Description de votre demande : </strong></p>
          <p>{req.message}</p>
        </div>
        <div className="table-cell">
          <div className="action-cell">
            <button className="blue-button" onClick={this.requestDetail.bind(this, req.numAppel)}>Voir le détail</button>
            {req.dateCloture === '' && req.bonPourCloture !== "E" ? (
            <button onClick={this.clotureRequest.bind(this, req.numAppel)} className="blue-button">Clôturer</button>) : ('')
            }
            {req.bonPourCloture === "E"? (
              <div>Votre demande de clôture a été transmise</div>
            ): ''}

          </div>
        </div>
      </div>);
    })

    let nbRequests = (this.props.requests ? this.props.requests.length : 0) + (this.props.closedRequests ? this.props.closedRequests.length : 0);
    let currentRequests = (this.props.requests || []).filter((req)=>{
      return req.dateCloture === '';
    }).length;

    return (
      <div>
        <ReactTitle title="Mes demandes | SOGIMA & Moi"/>
        <Header triggerMenu={this.triggerMenu.bind(this)} logout={this.props.logout} history={this.props.history} notifs={this.props.messages ? this.props.messages.length : '.'} />
        <div className="page-body logged">
          <Container>
            <Row>
              <Col md={3} lg={3}>
              <Menu opened={this.state.triggerMenu} current='requests' logout={this.props.logout} />
              </Col>
              <Col md={9} lg={9}>
              <div className="page-content">
                <Infos user={this.props.currentUser} />
                <div className="status-request-block clearfix">
                  <div>Demande(s) d'intervention(s) en cours : <strong>{currentRequests}/{nbRequests}</strong></div>
                  { today.length < 3 ?
                  (<Link className="orange-button create-request" to='/new-request'> Créer une demande d'intervention </Link>) : ''
                  }
                </div>
                {this.props.isFetchingRequests || this.props.isFetching || this.props.isLogging ? (
                  <div className={"login-spinner"}>
                    Chargement ...
                  </div>
                ) : (
                  <div>
                    <div className="request-table tab">
                       <section className="display-table">
                        <header className="table-row">
                          <div className="table-cell">N° demande/date</div>
                          <div className="table-cell">Demande initiale</div>
                          <div className="table-cell">Actions</div>
                        </header>
                        {requests}
                      </section>

                    </div>
                    <div className="align-right mobile-center">
                    { this.state.displayClosed ?(
                        <button className="orange consult-link" onClick={this.fetchOpenedRequests.bind(this)}>Consulter les demandes en cours</button>
                      ):(
                        <button className="orange consult-link" onClick={this.fetchCloturedRequests.bind(this)}>Consulter les demandes clôturées</button>
                      )
                    }
                    </div>
                  </div>
                )}
              </div>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer connected='true' rgpd={this.props.rgpd.rgpd} />
      </div>
    );
  }
}
