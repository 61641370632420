import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_DOCUMENTS,
  FETCHED_DOCUMENTS,
  FETCHED_DOCUMENTS_ERROR
} from '../constants';

/**
 * Fetch a users from the db
 *
 * @export
 * @param {String:email, String:password}
 * @returns {Array} users
 */
export default function downloadDocument(id) {

  console.log('DOWNLOAD DOCUMENT', id);
  const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
  //if (!jeton)
    //dispatch({ type: FETCHING_DOCUMENTS });
  return function(dispatch) {
    let encodedId = window.btoa(unescape(encodeURIComponent(id )));
    //dispatch({ type: FETCHING_DOCUMENTS });
    return request.get(`${API_URL}/documents/${encodedId}`)
      .set({'apiclef': API_KEY, 'jeton': jeton})
      .on('error', function(err) {
        console.log(`[ERROR][Download document]`);
        console.log(err);
        //dispatch({ type: FETCHED_DOCUMENTS_ERROR, error: err });
      })
      .then(function(response) {
        if (response.body && response.body.ok && response.body.reponse) {
          console.log('[Download document] success', response.body.reponse.documents.bufferFic);

          let data = response.body.reponse.documents.bufferFic;
          data = data.split('-').join('+');
          data = data.split('_').join('/');
          data = data.split('\n').join('');

           var binary = atob(data.replace(/\s/g, ''));
          var len = binary.length;
          var buffer = new ArrayBuffer(len);
          var view = new Uint8Array(buffer);
          for (var i = 0; i < len; i++) {
              view[i] = binary.charCodeAt(i);
          }

          // create the blob object with content-type "application/pdf"
          var blob = new Blob( [view], { type: "application/pdf" });
          var url = URL.createObjectURL(blob);

          // Vérifier si l'User Agent contient des indications d'appareils mobiles
          if (/Mobi|Android|iPhone|iPad|Windows Phone/i.test(navigator.userAgent)) {
            // Créer un lien de téléchargement
            const a = document.createElement('a');
            a.href = url;
            a.download = 'sogima-extranet.pdf'; // Vous pouvez définir le nom du fichier ici
            a.style.display = 'none';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            var win = window.open();
            win.document.write('<iframe src="' + url + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>');
          }
        } else {
          console.log(`[ERROR][Download Document]`);
          console.log(response);
        }
        return result(response.body, 'errors');
      });
  };
};
