import React        from 'react';
import Select       from 'react-select';
import Header       from '../../shared/Header';
import Menu         from '../../shared/Menu';
import Footer       from '../../shared/Footer';
import {Container, Row, Col} from 'react-bootstrap';
import Infos        from '../../shared/Infos';
import moment from 'moment';
import 'moment/locale/fr';
import 'react-dates/initialize';
import { SingleDatePicker } from 'react-dates';
import {ReactTitle} from 'react-meta-tags';

export default class AutopayRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        bankName: "",
        accountName: "",
        iban: "",
        bic: "",
        levyDay: {value: "01", label: "01"}
    };
    moment.locale('fr');
  }

  componentWillMount() {
    //if (!this.props.currentUser)
      this.props.checkLogin();
    //if (!this.props.messages)
      this.props.fetchMessages();
    //if (!this.props.rgpd.rgpd)
      this.props.fetchRgpd();
  }

  bankNameChange(e){this.setState({bankName: e.target.value})}
  accountNameChange(e){this.setState({accountName: e.target.value})}
  ibanChange(e){this.setState({iban: e.target.value})}
  bicChange(e){this.setState({bic: e.target.value})}
  onDayChange(e){this.setState({levyDay: e})}

  render() {
    const {bankName} = this.state;
    const {accountName} = this.state;
    const {iban} = this.state;
    const {bic} = this.state;
    const {levyDay} = this.state;
    const options = [{ value: "01", label: "01"}, { value: "05", label: "05"}, { value: "12", label: "12"}];
    const user = this.props.currentUser;
    return (
      <div>
        <ReactTitle title="Mon compte | SOGIMA & Moi"/>
        <Header logout={this.props.logout} history={this.props.history} notifs={this.props.messages ? this.props.messages.length : '.'} />
        <div className="page-body logged">
          <Container>
          <Row>
            <Col md={3} lg={3}>
              <Menu opened={this.props.triggerModal} current='account' logout={this.props.logout}/>
            </Col>
            <Col md={9} lg={9}>
              <div className="page-content form-content">
                <Infos user={user}/>

                <h1>Formulaire de demande et prélèvement automatique</h1>

                <div className="field-row">
                  <label htmlFor="banque">Nom de la banque</label>
                  <input id="banque" type="text" value={bankName} onChange={this.bankNameChange.bind(this)}></input>
                </div>
                <div className="field-row">
                  <label htmlFor="compte">Intitulé du compte</label>
                  <input id="compte" type="text" value={accountName} onChange={this.accountNameChange.bind(this)}></input>
                </div>
                {/*
                <div className="field-row">
                  <label htmlFor="custom-date-pick">Prélever à partir du</label>
                  <SingleDatePicker
                    date={this.state.date} // momentPropTypes.momentObj or null
                    onDateChange={date => this.setState({ date })} // PropTypes.func.isRequired
                    focused={this.state.focused} // PropTypes.bool
                    onFocusChange={({ focused }) => this.setState({ focused })} // PropTypes.func.isRequired
                    id="custom-date-pick" // PropTypes.string.isRequired,
                  />
                </div>
                */}

                <div className="field-row">
                  <label htmlFor="iban">IBAN</label>
                  <input id="iban" type="text" value={iban} onChange={this.ibanChange.bind(this)}></input>
                </div>

                <div className="field-row clearfix">
                  <div className="half">
                    <label htmlFor="bic">BIC</label>
                    <input id="bic" type="text" value={bic} onChange={this.bicChange.bind(this)}></input>
                  </div>
                  <div className="half">
                    <label htmlFor="jour">Quel jour de chaque mois ?</label>
                    <Select
                      value={this.state.levyDay}
                      onChange={this.onDayChange.bind(this)}
                      options={options}
                      className="custom-react-select"
                    />
                  </div>
                </div>

                <div className="steps">
                  A la validation du formulaire, le téléchargement du mandat se lancera. Il vous suffira ensuite de :<br/>
                  1. L'imprimer<br/>
                  2. de le signer <br/>
                  3. et nous le retourner par courrier ou par mail
                </div>

                <button className="orange-button" onClick={this.submitForm.bind(this)}>Télécharger mon mandat</button>
              </div>
            </Col>
          </Row>
          </Container>
        </div>
        <Footer rgpd={this.props.rgpd.rgpd} connected='true' />
      </div>
    );
  }

  submitForm() {
    let params = this.state;
    params.levyDay = params.levyDay.value;
    console.log('SUBMIT REQUEST AUTOPAY', params);
    this.props.createAutopayRequest(params);
  }
}
