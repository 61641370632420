import * as Constants from '../constants';

export default function UserReducer(state = {
  current_user: null,
  isFetchingUser: false,
  isLogging: false,
  isLogged: false,
  message: ''
}, action) {
  switch (action.type) {
    case Constants.LOGGING_USER: 
      return {
        ...state,
        isLogging: true,
      };
    case Constants.LOGGED_USER: 
      return {
        ...state,
        isLogging: false,
        isLogged: true,
      };
    case Constants.LOGGED_USER_ERROR:
      return {
        ...state,
        isLogging: false,
        isLogged: false,
        error: action.error,
      };
    case Constants.FETCHING_USER: 
      return {
        ...state,
        isFetchingUser: true,
      };
    case Constants.FETCHED_USER: 
      return {
        ...state,
        isFetchingUser: false,
        current_user: action.user,
      };
    case Constants.FETCHED_USER_ERROR:
      return {
        ...state,
        isFetchingUser: false,
        error: action.error,
      };
    case Constants.EDITING_USER: 
      return {
        ...state,
        isEditingUser: true,
      };
    case Constants.EDITED_USER:
      return {
        ...state,
        isEditingUser: false,
        message: "Vos informations ont bien été modifiées"
      };
    case Constants.EDITED_USER_ERROR:
      return {
        ...state,
        isEditingUser: false,
        error: action.error,
      };
    default:
      return state;
  }
};
