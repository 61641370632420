import * as Constants from '../constants';

export default function messagesReducer(state = {
  isFetching: false,
  messages: null,
  triggerModal: false
}, action) {
  console.log('REDUCERS MESSAGES', action.type, action);
  switch (action.type) {
    case Constants.FETCHING_MESSAGES:
      return {
        ...state,
        isFetching: true
      };
    case Constants.FETCHED_MESSAGES:
      return {
        ...state,
        isFetching: false,
        messages: action.messages
      };
    case Constants.FETCHED_MESSAGES_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
};
