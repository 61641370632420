import React from 'react';
import { Link } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap'
import MediaQuery from 'react-responsive';

export default class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      burgerOpen: false
    };
  }

  componentWillMount() {
  }

  logout(){
    this.props.logout();
  }

  _redirect_to(path) {
    this.props.history.push(`/${path}`);
  }

  triggerModale() {
    this.props.triggerMenu();
    this.setState({ burgerOpen: !this.state.burgerOpen });
  }

  render() {
    return (
      <div className="header">
        <MediaQuery query="(min-width: 768px)">
          <Container>
            <Row>
              <Col md={12}>
                <Link to="/home">
                  <img src="/img/logo.png" alt="Logo Sogima" className="logo"/>
                </Link>
                <div className="page-title"><strong>SOGIMA & MOI</strong> | Espace locataires</div>
                <div className="right-header">
                  <Link to='/messages' className="notifs">{this.props.notifs || '.'}</Link>
                  <div className="logout"><button onClick={this.logout.bind(this)}>Me déconnecter</button></div>
                </div>
              </Col>
            </Row>
          </Container>
        </MediaQuery>
        <MediaQuery query="(max-width: 767px)">
          <div className={this.state.burgerOpen? "burger open":"burger"} onClick={this.triggerModale.bind(this)}></div>
          <div className="mobile-logo-block">
          <Link to='/home' className="home-link">
            <img src="/img/mobile-logo.png" alt="Logo Sogima" className="logo"/>
            Espace locataires
          </Link>
          </div>
          <Link to='/messages' className="notifs">{this.props.notifs || '.'}</Link>
        </MediaQuery>
      </div>
    );
  }
}