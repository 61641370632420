import { connect } from 'react-redux';
import RequestComponent from '../components/Request';
import fetchRequest from '../../../modules/requests/actions/fetchRequest';
import fetchMessages from '../../../modules/messages/actions/fetchMessages';
import logout from '../../../modules/login/actions/logout';
import fetchRgpd from '../../../modules/rgpd/actions/fetchRgpd';

function mapStateToProps(state) {
  return {
    isLogging: state.login.isLogging || false,
    isFetching: state.login.isFetching || state.user.isFetching || state.requests.isFetching || false,
    isConnected:  state.login.isConnected || false,
    failedCheck:  state.login.failedCheck || false,
    currentUser:  state.login.currentUser || null,
    requests:     state.requests.requests || null,
    isFetchingRequest:  state.requests.isFetchingRequest || false,
    request:      state.requests.request || null,
    messages:     state.messages.messages || null,
    rgpd: state.rgpd || null
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchRequest(id){
      dispatch(fetchRequest(id));
    },
    fetchMessages(){
      dispatch(fetchMessages());
    },
    logout(){
      dispatch(logout());
    },
    fetchRgpd() {
      dispatch(fetchRgpd());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RequestComponent);
