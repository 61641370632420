import { connect }    from 'react-redux';
import EditProfile    from '../components/EditProfile';
import editUser       from '../../../modules/user/actions/editUser';
import checkLogin     from '../../../modules/login/actions/checkLogin';
import fetchMessages  from '../../../modules/messages/actions/fetchMessages';
import logout         from '../../../modules/login/actions/logout';
import fetchRgpd from '../../../modules/rgpd/actions/fetchRgpd';

function mapStateToProps(state) {
  console.log('MAPSTATETOPROPS EDIT PROFILE', state);
  return {
    error:        state.requests.error || null,
    currentUser:  state.login.currentUser || null,
    isFetching:   state.login.isFetching || state.user.isFetching ||false,
    isLogging:    state.login.isLogging || state.user.isLogging || state.messages.isLogging || false,
    isConnected:  state.login.isConnected || false,
    failedCheck:  state.login.failedCheck || false,
    message:      state.messages.message || null,
    messages:     state.messages.messages || null,
     rgpd: state.rgpd || null
   };
}

function mapDispatchToProps(dispatch) {
  return {
    editUser(form, userId) {
      dispatch(editUser(form, userId));
    },
    checkLogin() {
      dispatch(checkLogin());
    },
    fetchMessages(){
      dispatch(fetchMessages());
    },
    logout(){
      dispatch(logout());
    },
    fetchRgpd() {
      dispatch(fetchRgpd());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
