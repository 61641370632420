import { connect }    from 'react-redux';
import login          from '../../../modules/login/actions/login'
import Login          from '../components/Login';
import checkLogin     from '../../../modules/login/actions/checkLogin'
import forgotPassword from '../../../modules/login/actions/forgotPassword';

function mapStateToProps(state) {
  return {
    isConnected: state.login.isConnected || false,
    isLogging: state.login.isLogging || false,
    failedCheck: state.login.failedCheck || false,
    currentUser: state.login.currentUser || null,
    error: state.login.error || null,
    pswMessage: state.login.pswMsg || null,
    pswSuccess: state.login.pswSuccess || false
   };
}

function mapDispatchToProps(dispatch) {
  return {
    login({ email, password }) {
      dispatch(login({ email, password }))
    },
    checkLogin() {
      dispatch(checkLogin())
    },
    forgotPassword(form){
      dispatch(forgotPassword(form));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
