import { combineReducers } from 'redux';
//import customers from './customers/reducers';
//import identifier from './identifier/reducers';
import login from './login/reducers';
import home from './home/reducers';
import requests from './requests/reducers';
import user from './user/reducers';
import account from './account/reducers';
import documents from './documents/reducers';
import messages from './messages/reducers';
import conso from './conso/reducers';
import rgpd from './rgpd/reducers'

export default combineReducers({
   login, home, user, account, documents, messages, requests, conso, rgpd
});
