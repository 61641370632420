import React from 'react';
import { a } from 'react-router-dom'

export default class MainFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentWillMount() {
  }

  render() {
    return (
      <div className="main-footer">
        <a href="https://www.sogima.fr/global/mentions-legales" target="_blank">Mentions légales</a> <span className="mobile-rotate">|</span> <a href="https://www.sogima.fr/global/politique-de-confidentialite" target="_blank">Politiques de confidentialité</a> <span className="mobile-rotate">|</span> <a href="https://www.sogima.fr/locataires/accueil" target="_blank">Besoin d'aide ?</a> <span className="mobile-rotate">|</span> <a href="https://www.sogima.fr/global/nous-contacter" target="_blank">Nous contacter</a>
      </div>
    );
  }
}