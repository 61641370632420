import React        from 'react';
import moment       from 'moment';

export default class Conso extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
  }

  render() {
    let conso = this.props.data;
    if (!conso)
      return (<div></div>)
    return (
      <div className="conso-table-row" key={this.props.key}>
        <section className="display-table">
          <div className="table-row">
            <div className="table-cell">
              <div><strong>Local :</strong> {conso.c_local} </div>
              <div><strong>Compteur :</strong> {conso.numCompteur || 'Aucun compteur renseigné'}</div>
              <div><strong>Bail :</strong> {conso.n_bail}</div>
              <div><strong>Date :</strong> {moment(conso.date).format('DD/MM/YYYY')}</div>
            </div>
            <div className="table-cell">{conso.typeConsommation}</div>
            <div className="table-cell">
              <div><strong>Ancien index :</strong> {conso.ancienIndex} {conso.libelle_unite}</div>
              <div><strong>Nouvel index :</strong> {conso.nouvelleIndex} {conso.libelle_unite}</div>
              <div><strong>> soit une consommation de :</strong> {conso.consommation} {conso.libelle_unite}</div>
            </div>
            <div className="table-cell">
              {(() => {
                switch (conso.infoFacture) {
                  case "N":   return "Sera facturé prochainement";
                  case "O": return "Déjà Facturé";
                }
              })()}
            </div>
          </div>
        </section>
        <div className="observation-commentary">
          <strong>Observation/Commentaire : {conso.messageObservation}</strong>
        </div>
      </div>
    );
  }
}
