import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_REQUEST,
  FETCHED_REQUEST,
  FETCHED_REQUEST_ERROR
} from '../constants';

/**
 * Create a request
 * 
 * @export
 * @param {{ }}
 * @returns {Obj} request
 */
export default function fetchRequest(id = {}) {
  console.log('MEMEOW', id);
  return function (dispatch) {
    dispatch({ type: FETCHING_REQUEST });
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
    return request.get(`${API_URL}/reclamations/${id}`)
      .set({apiclef: API_KEY, jeton: jeton})
      .query({idReclamation: id})
      .send({})
      .then(response => {
        const request = result(response.body.reponse, 'reclamations');

        if (!!request) {
          console.log('[REQUEST] has been Fetched');
          dispatch({ type: FETCHED_REQUEST, request });
        } else {
          console.log('[ERROR][Requet] has not been Fetched', response.body);
          dispatch({ type: FETCHED_REQUEST_ERROR, error: result(response.body, 'message') });  
        }

        //dispatch(fetchRequest());
        return result(response.body, 'error');
      });
  };
};
