import * as Constants from '../constants';

export default function DataReducer(state = {
  isFetchingData: false,
  error: null,
  demands: null,
  triggerModal: false
}, action) {
  console.log('HOME REDUCER', action);
  switch (action.type) {
    case Constants.FETCHING_DATA:
      return {
        ...state,
        isFetchingData: true,
        isFetching: true,
      };
    case Constants.FETCHED_DATA:
      return {
        ...state,
        isFetchingData: false,
        isFetching: false,
        demands: action.demands,
      };
    case Constants.FETCHED_DATA_ERROR:
      return {
        ...state,
        isFetchingData: false,
        isFetching: false,
        error: action.error,
      };
    default: 
      return state;
  }
};
