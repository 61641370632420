import request from '../../../utils/superagent';
import { result } from 'lodash';
import API_URL from '../../../utils/API_URL';
import API_KEY from '../../../utils/API_KEY';

import {
  FETCHING_QUITTANCE,
  FETCHED_QUITTANCE,
  FETCHED_QUITTANCE_ERROR
} from '../constants';

/**
 * Fetch a demands from the db
 *
 * @export
 * @returns {Array}
 */
export default function fetchQuittance(quittanceId) {
  return function (dispatch) {
    dispatch({ type: FETCHING_QUITTANCE });
    const jeton = window.localStorage.getItem('sogima-auth') || window._csrf;
    return request.get(`${API_URL}/ecrituresComptables/${quittanceId}`)
      .set({apiclef: API_KEY, jeton: jeton})
      .send()
      .then(response => {
        console.log('FETCH QUITTANCE RESPONSE', response.body);
        const quittance = result(response.body.reponse, 'ecritures');
        if (!!quittance) {
          console.log(`[Conso] has been fetched`);
          dispatch({ type: FETCHED_QUITTANCE, quittance });
        } else {
          console.log(`[ERROR][Conso] has not been fetched`);
          dispatch({ type: FETCHED_QUITTANCE_ERROR, error: result(response.body, 'error') });
        }
        return quittance || result(response.body, 'error');
      });
  };
};
